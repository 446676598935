import React, { useContext } from 'react'
import { Button, Container, Col, Row, Card } from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import httpClient from '../../services/httpClient'
import appConfig from '../../app.config'
import { useTranslation } from 'react-i18next'
import SigningContext from '../../contexts/SigningContext'
import { appInsights } from '../../appInsights'

function SmartIdAdvSignBlock() {
    const [isWaiting, setIsWaiting] = React.useState(false)
    const [code, setVerificationCode] = React.useState('')
    const { t } = useTranslation()
    const { signingRequest, setSigned, setError } = useContext(SigningContext)

    const id = signingRequest?.id ?? ''

    async function doSmartIdSign() {
        setError(undefined)

        setIsWaiting(true)

        try {
            const userCountry = appConfig.country
            const signInitiate = await httpClient.smartIdSignInitiate({
                country: userCountry || '',
                signingRequestId: id,
            })
            const verification = signInitiate.verificationCode
            setVerificationCode(verification)
            setIsWaiting(false)

            await httpClient.smartIdSignComplete({
                verificationCode: verification || '',
                signatureValue: signInitiate.signatureValue || '',
                signingRequestId: id,
                containerId: signInitiate.containerID || '',
            })
            setSigned()
        } catch (exception) {
            appInsights.trackException({ exception })
            setError(exception)
        }

        setIsWaiting(false)
    }

    if (isWaiting) {
        return (
            <Container>
                <Col>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    if (code) {
        return (
            <Container>
                <Card>
                    <Card.Body className="verification-container">
                        <Row className="mx-auto">
                            <h4>{t('Verification code')}</h4>
                            <h1>{code}</h1>
                        </Row>
                        <DefaultSpinner />
                    </Card.Body>
                    <Card.Footer>
                        <Row className="notification">
                            <div className="col-1 glyphicon glyphicon-info-sign"></div>
                            <div className="col-11">
                                {t(
                                    'Notification sent to your smart device. Verify the code in the Smart-ID app and enter PIN2'
                                )}
                            </div>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            <Row>
                <Container>
                    <div>
                        <p>
                            {t(
                                'Please click the proceed button to sign the document'
                            )}
                        </p>
                    </div>
                </Container>
            </Row>
            <Row className="mt-2">
                <Button variant="primary" onClick={doSmartIdSign}>
                    {t('Proceed')}
                </Button>
            </Row>
        </Container>
    )
}

export default SmartIdAdvSignBlock
