import React, { useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import PdfViewer from "../PdfViewer";
import { documentTypes } from "../../constants/documentTypes";
import SigningStatus from "../../constants/signingStatuses";
import { useTranslation } from "react-i18next";
import SigningStage from "../../constants/signingStages";
import { PDFDocumentProxy } from "pdfjs-dist";
import { isPdf, downloadPdf } from "../../utils/helpers";
import SigningContext from "../../contexts/SigningContext";
import { getZonedTime, findTimeZone } from "timezone-support";
import appConfig from "../../app.config";
import TagManager from "../TagManager";
import { ITagManagerArgs } from "../../interfaces/i-tag-manager-args";
import { IDataLayer } from "../../interfaces/i-data-layer";
import { GtmEvents } from "../../constants/gtmEvents";

function getLithuaniaHours() {
    const vilnus = findTimeZone("Europe/Vilnius");
    const date = getZonedTime(new Date(), vilnus);

    return date.hours;
}

function signingForbidden(country: string) {

    const timeLt = getLithuaniaHours();
    const disableSigning = country.startsWith("lt") &&
        (timeLt >= 22 || timeLt < 7);

    return disableSigning;
}

function DefaultSigningBlock() {
    const { country } = appConfig;
    const { t } = useTranslation();
    const [pdf, setPdf] = React.useState<PDFDocumentProxy | Error>();
    const {
        signingRequest,
        setSigningStage,
    } = useContext(SigningContext);


    const id = signingRequest?.id ?? "";

    const disableSigning = signingForbidden(country);

    const fileName = `${signingRequest && t(documentTypes[signingRequest.documentType])
        }_${id}`;


    const trigger = React.useCallback(async () => {
        const auth = localStorage.getItem('authMethod');
        const tagManagerArgs = {
            dataLayer: {
                applicationID: signingRequest?.applicationId,
                authenticationMethod: auth,
                event: GtmEvents.AuthenticationSuccess
            } as IDataLayer
        } as ITagManagerArgs;

        TagManager.dataLayer(tagManagerArgs);
    }, [signingRequest]);

    React.useEffect(() => {
        trigger();
    }, [signingRequest, trigger])

    return (
        <Card className="PdfViewer defaultSigningBlock">
            <Card.Header>
                <h2>{signingRequest && t(documentTypes[signingRequest.documentType])}</h2>
            </Card.Header>

            <Card.Body>
                <Row>

                    <Col md={9}>
                        <PdfViewer
                            pdf={pdf}
                            setPdf={setPdf}
                            id={id}
                            status={signingRequest?.status} />
                    </Col>

                    <Col md={3}>

                        <Row className="m-4 signDisclaimer">
                            <span dangerouslySetInnerHTML={
                                { __html: t("Please read the detailed documents carefully, then sign them electronically", { interpolation: { escapeValue: false } }) }
                            } />
                        </Row>

                        {isPdf(pdf) && <Button
                            variant="outline-secondary"
                            className="my-4 w-100"
                            onClick={() => downloadPdf(pdf, `TF Bank ${fileName}.pdf`)}>
                            <span className="glyphicon glyphicon-download-alt mr-2" />
                            {t("Download PDF document")}
                        </Button>
                        }

                        {signingRequest?.status !== SigningStatus.signed &&
                            <Button
                                variant="primary"
                                className="w-100"
                                disabled={disableSigning}
                                onClick={() => setSigningStage(SigningStage.signing)}>
                                {t("Sign electronically")}
                            </Button>
                        }
                        {disableSigning &&
                            <p className="disabled">{t(
                                "unfortunately, you cannot sign your contract from {{from}} to {{to}}",
                                { from: "10.00 PM", to: "7.00 AM" }
                            )}</p>
                        }
                        <div id="divReturnLink" className="stateSigned">
                            <a href="/" className="btn btn-warning text-uppercase">{t("Return to TF Bank")}</a>
                        </div>
                    </Col>

                    {isPdf(pdf) &&
                        <Col xs={12} className="d-none d-lg-block">
                            {disableSigning &&
                                <p className="disabled">{t(
                                    "unfortunately, you cannot sign your contract from {{from}} to {{to}}",
                                    { from: "10.00 PM", to: "7.00 AM" }
                                )}</p>
                            }
                            {signingRequest?.status !== SigningStatus.signed &&
                                <Button
                                    variant="primary"
                                    className="w-100 mw300 my-5"
                                    disabled={disableSigning}
                                    onClick={() => setSigningStage(SigningStage.signing)}>
                                    {t("Sign electronically")}
                                </Button>
                            }

                        </Col>
                    }

                </Row>
            </Card.Body>

        </Card >
    )
}

export default DefaultSigningBlock;
