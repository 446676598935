import { isMobile } from '../utils/helpers'

enum AuthMethodType {
    Paysera = 0,
    Veriff = 1,
    MobileId = 2,
    CardId = 3,
    SmartIdAdv = 4,
    SmartIdBasic = 5,
    eParaksts = 15,
}

export default AuthMethodType

export const signingMethods: ISigningButton[] = [
    {
        title: 'Smart-ID',
        type: AuthMethodType.SmartIdAdv,
        showInMobile: true,
        className: 'EeSmartId',
    },
    {
        title: 'Smart-ID Basic',
        type: AuthMethodType.SmartIdBasic,
        showInMobile: true,
        className: 'EeSmartId',
    },
    {
        title: 'Mobile-ID',
        type: AuthMethodType.MobileId,
        showInMobile: true,
        className: 'EeMobileId',
    },
    {        
        title: 'Paysera',
        type: AuthMethodType.Paysera,
        showInMobile: true,
        className: 'PayseraId',
    },
    {
        title: 'Veriff',
        type: AuthMethodType.Veriff,
        showInMobile: true,
        className: 'Veriff',
    },
    {
        title: 'ID-Card',
        type: AuthMethodType.CardId,
        showInMobile: !isMobile(),
        className: 'EeIdCard',
    },
    {
        title: '',
        type: AuthMethodType.eParaksts,
        showInMobile: true,
        className: 'eParaksts',
    }
]

export const authMethods: ISigningButton[] = [    
    {
        title: 'Smart-ID',
        type: AuthMethodType.SmartIdAdv,
        showInMobile: true,
        className: 'EeSmartId',
    },
    {
        title: 'Mobile-ID',
        type: AuthMethodType.MobileId,
        showInMobile: true,
        className: 'EeMobileId',
    },    
    {
        title: 'Paysera',
        type: AuthMethodType.Paysera,
        showInMobile: true,
        className: 'PayseraId',
    },
    {
        title: 'ID-Card',
        type: AuthMethodType.CardId,
        showInMobile: !isMobile(),
        className: 'EeIdCard',
    },
    {
        title: '',
        type: AuthMethodType.eParaksts,
        showInMobile: true,
        className: 'eParaksts',
    },
]

export interface ISigningButton {
    title: string
    type: AuthMethodType
    showInMobile: boolean

    className: string
}
