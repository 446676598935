import React, { useContext } from "react";
import { isMobile } from "../../utils/helpers";
import { Card, Row } from "react-bootstrap";
import SigningButtons from "./SigningButtons";
import AuthMethodType from "../../constants/signingMethods";
import IdCardSignBlock from "./IdCardSignBlock";
import MobileIdSignBlock from "./MobileIdSignBlock";
import SmartIdAdvSignBlock from "./SmartIdAdvSignBlock";
import PayseraSignBlock from "./PayseraSignBlock";
import payseraStatuses from "../../constants/payseraStatuses";
import { useTranslation } from "react-i18next";
import SigningContext from "../../contexts/SigningContext";
import EParakstsSignBlock from "./EParakstsSignBlock";
import { documentTypes } from "../../constants/documentTypes";

function SigningBlock() {

    const { t } = useTranslation();
    const {
        signingRequest,
        signingStatus,
        user
    } = useContext(SigningContext);

    const [signingMethod, setSigningMethod] = React.useState<AuthMethodType | undefined>(() => {

        if (signingStatus === payseraStatuses.complete) return AuthMethodType.Paysera;

        // for consent signing preselect SmartId by default
        if(signingRequest?.documentType !== documentTypes.promissoryNote && signingRequest?.signingMethods.includes(AuthMethodType.SmartIdAdv)){
            return AuthMethodType.SmartIdAdv
        }

        if (user && user.profile.AuthMethodType in AuthMethodType && signingRequest?.signingMethods.includes(AuthMethodType[user.profile.AuthMethodType] as unknown as AuthMethodType)) {

            return AuthMethodType[user.profile.AuthMethodType] as unknown as AuthMethodType;
        }

        return signingRequest?.signingMethods[0];
    });

    function getSigningView() {

        switch (signingMethod) {
            case AuthMethodType.CardId:
                localStorage.setItem('signMethod', AuthMethodType[signingMethod]);
                return !isMobile() && <IdCardSignBlock />;

            case AuthMethodType.MobileId:
                localStorage.setItem('signMethod', AuthMethodType[signingMethod]);
                return <MobileIdSignBlock />;

            case AuthMethodType.Paysera:
                localStorage.setItem('signMethod', AuthMethodType[signingMethod]);
                return <PayseraSignBlock />;

            case AuthMethodType.SmartIdAdv:
            case AuthMethodType.SmartIdBasic:
                localStorage.setItem('signMethod', AuthMethodType[signingMethod]);
                return <SmartIdAdvSignBlock />;
            
            case AuthMethodType.eParaksts:
                    localStorage.setItem('signMethod', AuthMethodType[signingMethod]);
                    return <EParakstsSignBlock />;

            default:
                return <h3 className="text-center">{t("No signing method exist for this request")}</h3>;
        }
    }

    // @ts-ignore
    const buttons = <SigningButtons
        methods={signingRequest && signingRequest.signingMethods}
        active={signingMethod}
        onClick={setSigningMethod} />;

    return (
        <Card className="signingBlock">
            <Card.Header>
                <Row className="authButtons">

                    {buttons}

                    <div className="buttonsBottomBorder"></div>

                </Row>
            </Card.Header>
            <Card.Body>
                {getSigningView()}
            </Card.Body>
        </Card>
    )
}

export default SigningBlock;
