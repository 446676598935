import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {

    const { t } = useTranslation();

    return <div id="footer" className="container-fluid">
        <div className="navbar navbar-fixed-bottom" id="footerContent">
            <p className="text-center">
                &copy; {t("__footer")}
            </p>
        </div>
    </div>
}

export default Footer;