import React, { useContext } from 'react'
import { Button, Container, Col, Row } from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import httpClient from '../../services/httpClient'
import { useTranslation } from 'react-i18next'
import SigningContext from '../../contexts/SigningContext'
import { appInsights } from '../../appInsights'
import { appRoutes } from '../../constants/routes'
import { appConstants } from '../../constants/appConstants'

function EParakstsSignBlock() {
    const [isWaiting, setIsWaiting] = React.useState(false)
    const { t, i18n } = useTranslation()
    const { signingRequest, setError } = useContext(SigningContext)

    const id = signingRequest?.id ?? ''

    async function doEParakstsSign() {
        setError(undefined)

        setIsWaiting(true)

        try {
            const selectedLanguage = i18n.language
            sessionStorage.setItem(appConstants.signingRequestId, id)

            const redirectUrl = await httpClient.eParakstsSignInitiate({
                locale: selectedLanguage || '',
                signingRequestId: id,
                callbackUrl: window.location.origin + appRoutes.eParakstsSignCallback
            })
            
            window.location.href = redirectUrl
            
        } catch (exception) {
            appInsights.trackException({ exception })
            setError(exception)
        }

        setIsWaiting(false)
    }

    if (isWaiting) {
        return (
            <Container>
                <Col>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    return (
        <Container>
            <Row>
                <Container>
                    <div>
                        <p>
                            {t(
                                'Please click the proceed button to sign the document'
                            )}
                        </p>
                    </div>
                </Container>
            </Row>
            <Row className="mt-2">
                <Button variant="primary" onClick={doEParakstsSign}>
                    {t('Proceed')}
                </Button>
            </Row>
        </Container>
    )
}

export default EParakstsSignBlock
