import Snippets from './Snippets';
import { ITagManagerArgs } from '../interfaces/i-tag-manager-args';

const TagManager = {
    dataScript: function (dataLayer: any) {
        const script = document.createElement('script');
        script.innerHTML = dataLayer;
        return script;
    },
    gtm: function (args: any) {
        const snippets = Snippets.tags(args);

        const noScript = () => {
            const noscript = document.createElement('noscript')
            noscript.innerHTML = snippets.iframe
            return noscript
        }

        const script = () => {
            const script = document.createElement('script')
            script.innerHTML = snippets.script
            return script
        }

        const dataScript = this.dataScript(snippets.dataLayerVar)

        return {
            noScript,
            script,
            dataScript
        }
    },

    initialize: function ({ gtmId, dataLayer }: ITagManagerArgs) {
        const gtm = this.gtm({
            id: gtmId,
            dataLayer: dataLayer || undefined
        })
        if (dataLayer) document.head.appendChild(gtm.dataScript);
        document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
        document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
    },

    dataLayer: function ({ dataLayer }: ITagManagerArgs) {
        if ((window as { [key: string]: any })['dataLayer']) return (window as { [key: string]: any })['dataLayer'].push(dataLayer);
        const snippets = Snippets.dataLayer(dataLayer);
        const dataScript = this.dataScript(snippets);
        document.head.insertBefore(dataScript, document.head.childNodes[0]);
    }
}

export default TagManager;