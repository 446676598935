import React from "react";
import { useTranslation } from "react-i18next";

function SmartIdBasicContent() {

    const { t } = useTranslation();

    const line1 = t("Smart-ID Basic");
    const line2 = t("Line 2");

    return (
        <div className="col-12">
            <p>{line1}</p>
            <p>{line2} </p>
        </div>
    )

}

export default SmartIdBasicContent
