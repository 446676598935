enum SigningStatus {
    unsigned = 0,

    signed = 1,

    expired = 2,

    notFound = 3,

    authenticated = 4,

    failed = 5,
}

export default SigningStatus
