import React, { useState } from "react";
import { Container, Row, Col, ListGroup, Button, FormControl } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { appRoutes } from "../../constants/routes";
import { useTranslation } from "react-i18next";

function SignRootPage({ history }: RouteComponentProps<any>) {

    const { t } = useTranslation();
    const [signingRequestId, setSigningRequestId] = useState("");

    function onSigningRequestIdChange(e: React.FormEvent<any>) {

        setSigningRequestId(e.currentTarget.value || "");
    }

    function goToSigningRequest() {

        history.push(`${appRoutes.signRoot}/${signingRequestId}`);
    }

    return (
        <Container className="signingRoot">
            <ListGroup>
                <Row className="title text-center d-block">
                    <Col>
                        <h2>{t("Please provide signing request ID")}</h2>
                    </Col>
                </Row>
                <ListGroup.Item>
                    <Row>
                        <Col xs={10} sm={6} className="mx-auto my-4">
                            <FormControl
                                className="mt-2 mb-3"
                                name="signingRequestId"
                                id="signingRequestId"
                                placeholder={t("Signing Request ID")}
                                onChange={onSigningRequestIdChange}
                                value={signingRequestId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" type="button" onClick={goToSigningRequest}>{t("Proceed")}</Button>
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </Container>
    )
}

export default SignRootPage;