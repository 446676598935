import React from 'react'
import {
    Container,
    Row,
    Button,
    FormControl,
    Col,
    Card,
    Form,
} from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import appConfig from '../../app.config'
import httpClient from '../../services/httpClient'
import { getReturnUrl } from '../../utils/helpers'
import { appRoutes } from '../../constants/routes'
import { getQueryParams } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { SmartIdInitiateAuthDto } from '../../interfaces/i-smart-id'
import { validateSsn } from '../../utils/validation.helper'
import { appInsights } from '../../appInsights'

interface SmartIdLoginBlockProps {
    id: string
    onSuccess(str: string): void
    onError(error: Error | undefined): void
}

function SmartIdAdvLoginBlock({ id, onError }: SmartIdLoginBlockProps) {
    const [isWaiting, setIsWaiting] = React.useState(false)
    const [smartIdData, setSmartIdData] =
        React.useState<SmartIdInitiateAuthDto>()
    const [nationalIdentityNumber, setNationalIdentityNumber] =
        React.useState('')
    const [nationalIdentityNumberIsValid, setNationalIdentityNumberIsValid] =
        React.useState(true)
    const { t } = useTranslation()
    const getCancelUrl = () => window.location.reload;
    
    async function doSmartIdAuth() {
        onError(undefined)

        const isValid = validateNationalIdentityNumber()

        if (!isValid) {
            return
        }

        setIsWaiting(true)

        try {
            const { SigningRequestId } = getQueryParams()
            const signingRequestId = id || SigningRequestId || ''
            const countryCode = appConfig.country
            const data = await httpClient.smartIdAuthInit({
                signingRequestId,
                countryCode,
                nationalIdentityNumber,
                returnUrl: getReturnUrl(),
            })

            setSmartIdData(data)
            setIsWaiting(false)

            const { redirectUrl, secret } =
                await httpClient.smartIdAuthComplete(data)

            httpClient.redirectTo(
                redirectUrl || window.location.origin + appRoutes.signRoot,
                secret
            )
        } catch (exception) {
            appInsights.trackException({ exception })
            onError(exception)
            setIsWaiting(false)
        }
    }

    function onNationalIdentityNumberChange(e: React.FormEvent<any>) {        
        setNationalIdentityNumber(e.currentTarget.value || '')
    }

    function validateNationalIdentityNumber() {
        const isValid = validateSsn(nationalIdentityNumber)
        setNationalIdentityNumberIsValid(isValid)
        return isValid
    }

    if (isWaiting) {
        return (
            <Container className="smartIdAdv">
                <Col xs={12}>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    if (smartIdData) {
        return (
            <Container className="smartIdAdv">
                <Card>
                    <Card.Body className="verification-container">
                        <Row className="mx-auto">
                            <Col xs={12}>
                                <h4>{t('Verification code')}</h4>
                                <h1>{smartIdData.code}</h1>
                            </Col>

                            <DefaultSpinner />
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="notification">
                            <div className="col-12 col-sm-1 glyphicon glyphicon-info-sign" />
                            <div className="col-12 col-sm-11">
                                {t(
                                    'Notification sent to your smart device. Verify the code in the Smart-ID app and enter PIN1'
                                )}
                            </div>
                        </Row>
                    </Card.Footer>
                </Card>
                <CancelButton />
            </Container>
        )
    }

    function CancelButton() {
        if (document.referrer) {
            return (
                <Col className="d-flex justify-content-center cancelLogin">
                    <Button variant="light" onClick={getCancelUrl}>
                        {t('Cancel')}
                    </Button>
                </Col>
            )
        }

        return null
    }

    return (
        <Container className="smartIdAdv">
            <Col xs={12} className="documentNumber mx-auto">
                <p>{t('Please provide Smart-ID document number')}</p>
            </Col>

            <Col xs={12} className="mx-auto">
                <Col xs={12}>
                    <FormControl
                        type="text"
                        id="nationalIdentityNumber"
                        name="nationalIdentityNumber"
                        className="mt-2 formControl"
                        placeholder={t('Personal identity code')}                        
                        onChange={onNationalIdentityNumberChange}
                        onBlur={validateNationalIdentityNumber}
                        isInvalid={!nationalIdentityNumberIsValid}
                        value={nationalIdentityNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                        {t('please enter the correct personal number')}
                    </Form.Control.Feedback>
                </Col>
            </Col>

            <Col xs={12} className="mx-auto mt-4">
                <Button variant="primary" onClick={doSmartIdAuth}>
                    {t('Proceed')}
                </Button>
            </Col>
        </Container>
    )
}

export default SmartIdAdvLoginBlock

