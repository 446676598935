import appConfig from '../app.config'

export const appRoutes = {
    diagnostic: '/diagnostic',
    login: '/login',
    loginWithRequestId: '/login/:requestId?',
    sign: '/sign/:id/:status?',
    paysera: '/paysera/:id',
    signRoot: '/sign',
    callback: '/callback',
    error: '/errorpage',
    logout: '/logout',
    eParakstsCallback: '/eparaksts/callback',
    eParakstsSignCallback: '/eparaksts/signcallback',
}

export const apiRoutes = {
    adminPing: `${appConfig.apiBaseUrl}/api/admin/ping`,
    request: (id: string) => `${appConfig.apiBaseUrl}/api/sign/req/${id}`,
    requestAuthInfo: (id: string) =>
        `${appConfig.apiBaseUrl}/api/sign/req/${id}/authinfo`,
    requestSignedMethod: (id: string) =>
        `${appConfig.apiBaseUrl}/api/sign/signmethod/${id}`,
    idCardInitiate: (id: string) =>
        `${appConfig.apiBaseUrl}/api/idcard/${id}/initiate`,
    idCardComplete: (id: string) =>
        `${appConfig.apiBaseUrl}/api/idcard/${id}/complete`,
    signingStatus: (id: string) =>
        `${appConfig.apiBaseUrl}/api/sign/status/${id}`,
    originalPdf: (id: string) => `${appConfig.apiBaseUrl}/api/sign/orig/${id}`,
    signedPdf: (id: string) => `${appConfig.apiBaseUrl}/api/sign/signed/${id}`,
    payseraInitiate: (id: string, payment: string, mode = '0') =>
        `${appConfig.apiBaseUrl}/api/paysera/${id}/initiate/${payment}?mode=${mode}`,
    payseraComplete: (id: string) =>
        `${appConfig.apiBaseUrl}/api/paysera/${id}/complete`,
    payseraCallback: (id: string) =>
        `${appConfig.apiBaseUrl}/api/paysera/${id}/callback`,
    payseraStatus: (id: string) =>
        `${appConfig.apiBaseUrl}/api/paysera/${id}/status`,
    mobileIdSignInitiate: `${appConfig.apiBaseUrl}/api/mobileid/sign/initiate`,
    mobileIdSignComplete: `${appConfig.apiBaseUrl}/api/mobileid/sign/complete`,
    smartIdSignInitiate: `${appConfig.apiBaseUrl}/api/smartid/sign/initiate`,
    smartIdSignInitComplete: `${appConfig.apiBaseUrl}/api/smartid/sign/complete`,
    idCardAuth: (id: string) =>
        `${appConfig.authorityBaseUrl}/apiauth/idcard/${id || ''}`,
    mobileIdAuthInitiate: `${appConfig.authorityBaseUrl}/apiauth/mobileid/initiate`,
    mobileIdAuthComplete: `${appConfig.authorityBaseUrl}/apiauth/mobileid/complete`,
    smartIdAuthInitiate: `${appConfig.authorityBaseUrl}/apiauth/smartid/initiate`,
    smartIdAuthComplete: `${appConfig.authorityBaseUrl}/apiauth/smartid/complete`,
    eParakstsGetAuthLink: (id: string, returnUrl:string, locale:string, callbackUrl:string) =>
        `${appConfig.authorityBaseUrl}/apiauth/eparaksts/link?signingRequestId=${id}&locale=${locale}&callbackUrl=${callbackUrl}&returnUrl=${returnUrl}`,
    eParakstsAuthResult: `${appConfig.authorityBaseUrl}/apiauth/eparaksts/result`,
    eParakstsSignInitiate: `${appConfig.apiBaseUrl}/api/eparaksts/sign/initiate`,
    eParakstsSignComplete: `${appConfig.apiBaseUrl}/api/eparaksts/sign/complete`,
    redirectTo: `${appConfig.authorityBaseUrl}/account/redirectto`,
    certificateFunctionCode: `${appConfig.authorityBaseUrl}/api/certificatefunction/code`,
    googleTagManager: (key: string | undefined) =>
        `${appConfig.authorityBaseUrl}/api/gtm/${key}`,
}
