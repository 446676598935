import React, { useContext } from "react";
import AuthMethodType, { signingMethods } from "../../constants/signingMethods";
import { useTranslation } from "react-i18next";
import SigningContext from "../../contexts/SigningContext";

interface ISigningButtonProps {
    methods: AuthMethodType[] | undefined;
    onClick: any;

    active: AuthMethodType | undefined;
}

function SigningButtons({
    methods,
    onClick,
    active
}: ISigningButtonProps) {

    const { t } = useTranslation();

    const { user } =
        useContext(SigningContext)

    const signingButtons = !methods
        ? []
        : signingMethods
            .filter(method => methods.indexOf(method.type) > -1);

    function isChosen(methodId: number) {
        return String(active) === String(methodId) ? " chosen" : "";
    }

    return signingButtons.map(b => b.showInMobile && (b.type !== AuthMethodType.eParaksts || user?.profile?.AuthMethodType === 'EParaksts') && 
        <div
            key={b.type}
            className={"authButton " + b.className + isChosen(b.type)}
            onClick={() => onClick(b.type)}>
            {t(b.title)}
        </div>
    )
}

export default SigningButtons;