import React, { Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { possiblyReturnSigningClass } from '../src/utils/helpers'
import Header from './components/Header'
import LoginPage from './components/pages/LoginPage'
import LogoutPage from './components/pages/LogoutPage'
import ErrorPage from './components/pages/ErrorPage'
import FailedSign from './components/pages/FailedSign'
import CallbackPage from './components/pages/CallbackPage'
import SignRootPage from './components/pages/SignRootPage'
import SignPage from './components/pages/SignPage'
import { appRoutes } from './constants/routes'
import Footer from './components/pageBlocks/Footer'
import './App.scss'
import appConfig from './app.config'
import { SigningContextProvider } from './contexts/SigningContext'
import TagManager from './components/TagManager'
import { ITagManagerArgs } from '././interfaces/i-tag-manager-args'
import httpClient from './services/httpClient'
import './appInsights'
import { PayseraAuthCallback } from './components/pageBlocks/PayseraAuthCallback'
import { EParakstsAuthCallback } from './components/pageBlocks/EParakstsAuthCallback'
import { EParakstsSignCallback } from './components/pageBlocks/EParakstsSignCallback'

const queryString = require('query-string')
if (queryString.parse(window.location.search.toLowerCase()).style) {
    sessionStorage.setItem(
        'style',
        queryString
            .parse(window.location.search.toLowerCase())
            .style?.toString()!
    )
    import(
        `./styling/${queryString
            .parse(window.location.search.toLowerCase())
            .style?.toString()}.css`
    ).catch((e) => window.console.warn(e))
}

// do not change it to switch!!! it is optimized by compiler
if (appConfig.country === 'ee') {
    require('./App.ee.scss')
} else if (appConfig.country === 'lt') {
    require('./App.lt.scss')
}

const DiagnosticPage = React.lazy(
    () => import('./components/pages/DiagnosticPage')
)
const gtm = process.env.REACT_APP_GTM_ID

async function trigger() {
    const key = await httpClient.getGoogleTagManager(gtm)

    const tagManagerArgs: ITagManagerArgs = {
        gtmId: key,
        dataLayer: undefined,
    }
    TagManager.initialize(tagManagerArgs)
}

const App: React.FC = () => {
    React.useEffect(() => {
        trigger()
    }, [])

    return (
        <BrowserRouter>
            <div id="App" className={'App' + possiblyReturnSigningClass()}>
                <Suspense fallback={<></>}>
                    <Header />
                    <SigningContextProvider>
                        <Switch>
                            {appConfig.environment !== 'production' && (
                                <Route
                                    path={'/'}
                                    render={(props) => (
                                        <React.Suspense fallback={<div />}>
                                            <DiagnosticPage {...props} />
                                        </React.Suspense>
                                    )}
                                    exact
                                />
                            )}
                            <Route
                                path={appRoutes.loginWithRequestId}
                                component={LoginPage}
                                exact
                            />
                            <Route
                                path={appRoutes.logout}
                                component={LogoutPage}
                                exact
                            />
                            <Route
                                path={appRoutes.error}
                                component={ErrorPage}
                                exact
                            />
                            <Route
                                path={appRoutes.callback}
                                component={CallbackPage}
                                exact
                            />
                            <Route
                                path={appRoutes.diagnostic}
                                component={DiagnosticPage}
                                exact
                            />
                            {appConfig.environment !== 'production' && (
                                <Route
                                    path={appRoutes.signRoot}
                                    component={SignRootPage}
                                    exact
                                />
                            )}
                            {appConfig.environment === 'production' && (
                                <Route
                                    path={appRoutes.signRoot}
                                    component={FailedSign}
                                    exact
                                />
                            )}
                            <Route
                                path={appRoutes.sign}
                                component={SignPage}
                                exact
                            />
                            <Route
                                path={appRoutes.paysera}
                                component={PayseraAuthCallback}
                                exact
                            />
                            <Route
                                path={appRoutes.eParakstsCallback}
                                component={EParakstsAuthCallback}
                                exact
                            />
                            <Route
                                path={appRoutes.eParakstsSignCallback}
                                component={EParakstsSignCallback}
                                exact
                            />
                        </Switch>
                    </SigningContextProvider>
                    <Footer />
                </Suspense>
            </div>
        </BrowserRouter>
    )
}

export default App
