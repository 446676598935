import React from 'react'
import { Button } from 'react-bootstrap'
import AuthMethodType, { authMethods } from '../../constants/signingMethods'
import { useTranslation } from 'react-i18next'

interface IAuthButtonProps {
    methods: AuthMethodType[] | undefined
    onClick: any

    active: AuthMethodType | undefined
}

function AuthButtons({ methods, onClick, active }: IAuthButtonProps) {
    const { t } = useTranslation()

    const authButtons = !methods
        ? []
        : authMethods.filter((method) => methods.indexOf(method.type) > -1)

    function isChosen(methodId: number) {
        return String(active) === String(methodId) ? ' chosen' : ''
    }

    return (
        <>
            {authButtons.map(
                (b) =>
                    b.showInMobile && (
                        <div
                            key={b.type}
                            className={
                                'authButton ' + b.className + isChosen(b.type)
                            }
                            onClick={() => onClick(b.type)}
                        >
                            {t(b.title)}
                        </div>
                    )
            )}
        </>
    )
}

export default AuthButtons
