import React from 'react'
import { Row, Col, Button, Container, Alert } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'
import { appRoutes } from '../../constants/routes'
import {
    getErrorWithFullMessage,
    getQueryParams,
    isMobile,
} from '../../utils/helpers'
import AuthMethodType from '../../constants/signingMethods'
import MobileIdContentBlock from '../pageBlocks/MobileIdContentBlock' // 2
import IdCardContentBlock from '../pageBlocks/IdCardContentBlock' // 3
import SmartIdAdvContentBlock from '../pageBlocks/SmartIdAdvContentBlock' // 4
import SmartIdBasicContentBlock from '../pageBlocks/SmartIdBasicContentBlock' // 5
import { useTranslation } from 'react-i18next'
import httpClient from '../../services/httpClient'
import AuthButtons from '../pageBlocks/AuthButtons'
import TagManager from '../../components/TagManager'
import { ITagManagerArgs } from '../../interfaces/i-tag-manager-args'
import { IDataLayer } from '../../interfaces/i-data-layer'
import { GtmEvents } from '../../constants/gtmEvents'
import { appInsights } from '../../appInsights'
import appConfig from '../../app.config'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import { PayseraContentBlock } from '../pageBlocks/PayseraContentBlock'
import EParakstsContentBlock from '../pageBlocks/EParakstsContentBlock'

interface ILoginParams {
    requestId: string
    redirectUrl: string
}

function filterAuthMethods(methods: AuthMethodType[]) {
    if (!isMobile()) return methods

    return methods.filter((m) => m !== AuthMethodType.CardId)
}
function sortAuthMethods(methods: AuthMethodType[])
{
    methods.sort((a,b) => {
        if(a === AuthMethodType.SmartIdAdv)
            return -1;
        if(b === AuthMethodType.SmartIdAdv)
            return 1;
    
        if(a === AuthMethodType.SmartIdBasic)
            return -1;
        if(b === AuthMethodType.SmartIdBasic)
            return 1;
    
        if(a === AuthMethodType.MobileId)
            return -1;
        if(b === AuthMethodType.MobileId)
            return 1;
    
        if(a === AuthMethodType.Paysera)
            return -1;
        if(b === AuthMethodType.Paysera)
            return 1;
        
        return 0;
    });
}
function LoginPage({ match, history }: RouteComponentProps<ILoginParams>) {
    const [error, setError] = React.useState<Error>()
    const [success, setSuccess] = React.useState<string>()
    const [cancelUrl, setCancelUrl] = React.useState<string>()
    const [failUrl, setFailUrl] = React.useState<string>()
    const [applicationId, setAppId] = React.useState<string>()
    const [authMethod, setAuthMethod] = React.useState<
        AuthMethodType | undefined
    >()
    const [authMethodList, setAuthMethodList] = React.useState<
        AuthMethodType[]
    >([])
    const { t } = useTranslation()

    const checkCardError = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        const cardError = urlParams.get('cardError')
        const signId = urlParams.get('SigningRequestId')
        const authInfo = signId ? await httpClient.getAuthInfo(signId) : null
        setAppId(authInfo?.applicationId)
        if (cardError !== null) {
            return new Error(cardError)
        }
    }

    const trigger = React.useCallback(async () => {
        if (error !== undefined) {
            const tagManagerArgs = {
                dataLayer: {
                    applicationID: applicationId,
                    authenticationMethod: localStorage.getItem('authMethod'),
                    event: GtmEvents.AuthenticationFailed,
                    errorMessage: error.message,
                } as IDataLayer,
            } as ITagManagerArgs

            TagManager.dataLayer(tagManagerArgs)
        }
    }, [applicationId, error])

    React.useEffect(() => {
        trigger()
    }, [trigger])

    function setFullError(e: Error) {
        setError(getErrorWithFullMessage(e))
    }

    const getAuthMethods = React.useCallback(async () => {
        try {
            const error = await checkCardError()
            setError(error)

            const { SigningRequestId } = getQueryParams()

            const authInfo = SigningRequestId
                ? await httpClient.getAuthInfo(SigningRequestId)
                : null
            setAppId(authInfo?.applicationId)            
            const authMethods: AuthMethodType[] = filterAuthMethods(
                authInfo?.authMethods ?? 
                (appConfig.country === 'lv'
                    ? [AuthMethodType.SmartIdAdv,
                        AuthMethodType.eParaksts]
                    : [
                          AuthMethodType.CardId,
                          AuthMethodType.MobileId,
                          AuthMethodType.SmartIdAdv,
                      ])
            )
            
            sortAuthMethods(authMethods)
            
            const cancelUrl = authInfo?.cancelUrl ?? ''
            const failUrl = authInfo?.failUrl ?? ''

            setCancelUrl(cancelUrl)
            setFailUrl(failUrl)
            setAuthMethodList(authMethods)
            setAuthMethod(authMethods[0])
        } catch (e) {
            appInsights.trackException({ exception: e as Error })
            setFullError(e as Error)
        }
    }, [])

    React.useEffect(() => {
        getAuthMethods()
    }, [getAuthMethods])

    function getContentBlock() {
        switch (authMethod) {
            case AuthMethodType.MobileId:
                localStorage.setItem('authMethod', AuthMethodType[authMethod])
                return (
                    <MobileIdContentBlock
                        id={match.params.requestId}
                        onSuccess={onSuccess}
                        onError={setFullError}
                    />
                )

            case AuthMethodType.SmartIdAdv:
                localStorage.setItem('authMethod', AuthMethodType[authMethod])
                return (
                    <SmartIdAdvContentBlock
                        id={match.params.requestId}
                        onSuccess={onSuccess}
                        onError={setFullError}
                    />
                )

            case AuthMethodType.SmartIdBasic:
                localStorage.setItem('authMethod', AuthMethodType[authMethod])
                return <SmartIdBasicContentBlock />

            case AuthMethodType.CardId:
                localStorage.setItem('authMethod', AuthMethodType[authMethod])
                return (
                    <IdCardContentBlock
                        id={match.params.requestId}
                        onSuccess={onSuccess}
                        onError={setFullError}
                    />
                )

            case AuthMethodType.Paysera:
                localStorage.setItem('authMethod', AuthMethodType[authMethod])
                return (
                    <PayseraContentBlock
                        id={match.params.requestId}
                        onSuccess={onSuccess}
                        onError={setFullError}
                    />
                )

            case AuthMethodType.eParaksts:
                localStorage.setItem('authMethod', AuthMethodType[authMethod])
                return (
                    <EParakstsContentBlock
                        id={match.params.requestId}
                        onSuccess={onSuccess}
                        onError={setFullError}
                    />
                )

            default:
                return (
                    <Container>
                        <Col xs={12}>
                            <DefaultSpinner />
                        </Col>
                    </Container>
                )
        }
    }

    function onSuccess(str: string) {
        if (match.params.requestId) {
            history.push(`${appRoutes.signRoot}/${match.params.requestId}`)
        } else {
            setSuccess(str)
        }
    }

    const getCancelUrl = () => window.location.replace(cancelUrl as string)
    const getRedirectUrl = () => window.location.replace(failUrl as string)

    function CancelButton() {
        if (document.referrer && cancelUrl) {
            return (
                <Col className="d-flex justify-content-center cancelLogin">
                    <Button variant="light" onClick={getCancelUrl}>
                        {t('Cancel')}
                    </Button>
                </Col>
            )
        }

        return null
    }

    function BackToMerchant() {
        if (document.referrer && failUrl && error) {
            return (
                <Col className="d-flex justify-content-center cancelLogin">
                    <Button variant="light" onClick={getRedirectUrl}>
                        {t('Back to merchant')} &gt;
                    </Button>
                </Col>
            )
        }

        return null
    }

    // @ts-ignore

    const authButtons = (
        <AuthButtons
            methods={authMethodList}
            onClick={setAuthMethod}
            active={authMethod}
        />
    )

    return (
        <Container id="LoginPage">
            <Container className="loginHeadline d-flex flex-column">
                <h1>{t('Please authenticate using a method below')}</h1>
            </Container>
            <Container className="loginBody d-flex flex-column">
                {error && (
                    <Alert
                        variant="danger"
                        dismissible
                        onClose={() => setError(undefined)}
                    >
                        {t(error.message)}
                    </Alert>
                )}

                {success && (
                    <Alert
                        variant="success"
                        dismissible
                        onClose={() => setSuccess(undefined)}
                    >
                        {success}
                    </Alert>
                )}

                <Row className="authButtons">
                    {authButtons}

                    <div className="buttonsBottomBorder"></div>
                </Row>

                <Row className="contentBlock">{getContentBlock()}</Row>
            </Container>
            <BackToMerchant />
            <CancelButton />
        </Container>
    )
}

export default LoginPage
