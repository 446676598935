import React, { useContext } from "react";
import { Alert, Container, Col, Button } from "react-bootstrap";
import { DefaultSpinner } from "../pageBlocks/Spinners";
import { RouteComponentProps } from "react-router";
import { ISignRouteParam } from "../../interfaces/i-signing-page";
import SigningStage from "../../constants/signingStages";
import DefaultSigningBlock from "../pageBlocks/DefaultSigningBlock";
import SigningBlock from "../pageBlocks/SigningBlock";
import SigningCompleteBlock from "../pageBlocks/SigningCompleteBlock";
import SigningContext from "../../contexts/SigningContext";
import { useTranslation } from "react-i18next";

function SignPage({ match }: RouteComponentProps<ISignRouteParam>) {

    const {
        fetchSigningRequest,
        signingRequest,
        signingStage,
        error,
        setError
    } = useContext(SigningContext)
    const { t } = useTranslation();
    React.useEffect(
        () => {
            fetchSigningRequest(match.params.id, match.params.status);
        },
        [fetchSigningRequest, match, signingStage]
    )

    function getRenderBlock() {

        if (!signingRequest) return null;

        switch (signingStage) {
            case SigningStage.signing:

                return <SigningBlock />;

            case SigningStage.complete:

                return <SigningCompleteBlock />;

            default:
                return <DefaultSigningBlock />;
        }
    }

    function BackToMerchant() {

        if (error) {
            return (
                <Col className="d-flex cancelLogin">
                    <Button variant="light"
                        onClick={() => {
                            window.location.replace(signingRequest ? signingRequest.failUrl : "")
                        }}>
                        {t("Back to merchant")} &gt;
                    </Button>
                </Col>
            )
        }
        return null;
    }

    return (
        <Container id="SignPage">
            {!signingRequest && !error
                ? <DefaultSpinner />
                : <>
                    {error &&
                        <Alert variant="danger" dismissible onClose={() => signingRequest && setError(undefined)}>
                            {error.message}
                        </Alert>
                    }

                    {getRenderBlock()}
                    <BackToMerchant />
                </>
            }
        </Container>
    )
}

export default SignPage
