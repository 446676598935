import {
  ApplicationInsights,
  DistributedTracingModes,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import appConfig from './app.config';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin: any = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appConfig.instrumentationKey,
    enableUnhandledPromiseRejectionTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    distributedTracingMode: DistributedTracingModes.W3C,
    disableFetchTracking: false,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.data) {
    envelope.data.country = appConfig.country;
  }
});

const logEvent = (name: string, properties: { [key: string]: any }) => {
  appInsights.trackEvent({
    name: name,
    properties: { properties },
  });
};
const logError = (error: Error) => {
  appInsights.trackException({
    error: error,
    exception: error,
    severityLevel: SeverityLevel.Error,
  });
};

const logCritical = (error: Error) => {
  appInsights.trackException({
    error: error,
    exception: error,
    severityLevel: SeverityLevel.Critical,
  });
};
export { reactPlugin, appInsights, logError, logCritical, logEvent };
