import { UserManager, UserManagerSettings } from 'oidc-client';
import appConfig from '../app.config';
import { appInsights } from '../appInsights';

export function getClientSettings(response_mode: string | undefined = undefined): UserManagerSettings {

    return {
        authority: appConfig.authorityBaseUrl,
        client_id: 'tf_bank_signing_spa',
        redirect_uri: `${window.location.origin}/callback`,
        response_type: "code",
        loadUserInfo: true,
        post_logout_redirect_uri: `${window.location.origin}/logout`,
        scope: "openid tf_bank_signing_api", // Space after openid is correct.
        response_mode
    };
}

export function getUserManager() {

    return new UserManager(getClientSettings());
}

export const userManager = getUserManager();

userManager.events.addUserLoaded((user) => {
    appInsights.setAuthenticatedUserContext(user.profile.sub, undefined, true)
})