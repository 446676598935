import Axios from 'axios';
import { apiRoutes } from '../constants/routes';
import { IMobileIdAuthResponse } from '../interfaces/i-mobileid';
import { IUserData } from '../interfaces/i-user-data';
import { IParakstsIdAuthResponse } from '../interfaces/i-paraksts-auth-response';
import {
  ISigningRequest,
  ISigningStatus,
  ISigningAuthInfo,
} from '../interfaces/i-signing-request';
import { IPayseraCompleted } from '../interfaces/i-paysera';
import { checkErrorStatus401 } from '../utils/helpers';
import { SmartIdInitiateAuthDto } from '../interfaces/i-smart-id';
import { SignInitiateDto } from '../interfaces/i-sign-initiate';
import AuthMethodType from '../constants/signingMethods';
import { getHeadersWithJwtToken } from '../utils/auth-util';
import { appInsights } from '../appInsights';
import { setupInterceptors as signingInterceptors } from '../utils/Axios/Interceptors/signingInterceptors';
import { setupInterceptors as authenticationInterceptors } from '../utils/Axios/Interceptors/authInterceptors';

const transportWithCredentials = authenticationInterceptors(
  Axios.create({
    withCredentials: true,
    timeout: 1.5 * 60 * 1000,
  })
);

const trackSigningTelemetryFor = signingInterceptors(Axios.create({}));

const httpClient = {
  async mobileIdAuthInitiate(
    phoneNumber: string,
    signingRequestId: string,
    personalNumber: string,
    returnUrl: string,
    country: string
  ) {
    const { data } = await transportWithCredentials.post<IMobileIdAuthResponse>(
      apiRoutes.mobileIdAuthInitiate,
      {
        phoneNumber,
        signingRequestId,
        returnUrl,
        personalNumber,
        country,
      }
    );

    return data;
  },

  async mobileIdAuthComplete(userData: IUserData | undefined) {
    const { data } = await transportWithCredentials.post<{
      redirectUrl: string;
      secret: string;
    }>(
      apiRoutes.mobileIdAuthComplete,
      userData,
      await getHeadersWithJwtToken()
    );

    if (!data) {
      throw new Error('error trying to authenticated with Mobile-ID');
    }

    return data;
  },

  async mobileIdSignInitiate(payload: {
    signingRequestId: string;
    phoneNumber: string;
  }) {
    const { data } = await trackSigningTelemetryFor.post<SignInitiateDto>(
      apiRoutes.mobileIdSignInitiate,
      payload,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async mobileIdSignComplete(payload: {
    verificationCode: string;
    signatureValue: string;
    signingRequestId: string;
    containerId: string;
  }) {
    const { data } = await trackSigningTelemetryFor.post(
      apiRoutes.mobileIdSignComplete,
      payload,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async getLastSigningRequestId() {
    const { data } = await trackSigningTelemetryFor.get(apiRoutes.adminPing);

    return Array.isArray(data.requests) ? (data.requests[0] as string) : '';
  },

  async getSigningRequestById(id: string) {
    const { data } = await trackSigningTelemetryFor.get<ISigningRequest>(
      apiRoutes.request(id),
      await getHeadersWithJwtToken()
    );

    return data;
  },

  // async getCancelUrl(id: string) {

  //     const { data } = await Axios.get<string>(
  //         apiRoutes.cancelUrl(id),
  //         await getHeadersWithJwtToken()
  //     );
  //     return data;
  // },

  async idCardSignInitiate(id: string, certificateContent: string) {
    try {
      const { data } = await trackSigningTelemetryFor.post<SignInitiateDto>(
        apiRoutes.idCardInitiate(id),
        { certificateContent },
        await getHeadersWithJwtToken()
      );

      console.warn(JSON.stringify(data));

      return data;
    } catch (exception) {
      appInsights.trackException({ exception: exception as Error });
      if (!checkErrorStatus401(exception as Error)) {
        throw exception;
      }
    }
  },

  async idCardSignComplete(
    signingRequestId: string,
    signatureValue: string,
    containerId: string
  ): Promise<{ ok: boolean; url: string } | undefined> {
    try {
      const { data } = await trackSigningTelemetryFor.post(
        apiRoutes.idCardComplete(signingRequestId),
        { signatureValue, containerId, signingRequestId },
        await getHeadersWithJwtToken()
      );

      console.warn(JSON.stringify(data), 'idCardSignComplete');

      return data;
    } catch (exception) {
      appInsights.trackException({ exception: exception as Error });
      if (!checkErrorStatus401(exception as Error)) {
        throw exception;
      }
    }
  },

  async payseraComplete(id: string) {
    try {
      const { data } = await trackSigningTelemetryFor.get<IPayseraCompleted>(
        apiRoutes.payseraComplete(id),
        await getHeadersWithJwtToken()
      );

      return data;
    } catch (exception) {
      appInsights.trackException({ exception: exception as Error });
      if (!checkErrorStatus401(exception as Error)) {
        throw exception;
      }
    }
  },

  async payseraInitiate(id: string, bank: string, mode?: string) {
    try {
      const { data } = await trackSigningTelemetryFor.get(
        apiRoutes.payseraInitiate(id, bank, mode),
        await getHeadersWithJwtToken()
      );

      return data;
    } catch (exception) {
      appInsights.trackException({ exception: exception as Error });
      if (!checkErrorStatus401(exception as Error)) {
        throw exception;
      }
    }
  },

  async payseraStatus(id: string) {
    try {
      const { data } = await trackSigningTelemetryFor.get(
        apiRoutes.payseraStatus(id)
      );

      return data;
    } catch (exception) {
      appInsights.trackException({ exception: exception as Error });
    }
  },

  async checkSigningRequestStatus(id: string) {
    const { data } = await trackSigningTelemetryFor.get<ISigningStatus>(
      apiRoutes.signingStatus(id),
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async eParakstsAuthInit(authRequest: {
    signingRequestId: string;
    selectedLanguage: string;
    returnUrl: string;
    callbackUrl: string;
  }) {
    const { data } =
      await transportWithCredentials.get(
        apiRoutes.eParakstsGetAuthLink(authRequest.signingRequestId, authRequest.returnUrl, authRequest.selectedLanguage, authRequest.callbackUrl)
      );

    return data;
  },

  async eParakstsResultGet(queryParams: string, callbackUrl: string) {
		const data =
			await transportWithCredentials.get<IParakstsIdAuthResponse>(
				apiRoutes.eParakstsAuthResult +
				`${queryParams}&callbackUrl=${callbackUrl}`
			)

		if (data.status === 200) {
			return data.data
		}
		throw new Error('AuthenticationError')
	},

  async smartIdAuthInit(authRequest: {
    signingRequestId: string;
    countryCode: string;
    nationalIdentityNumber: string;
    returnUrl: string;
  }) {
    const { data } =
      await transportWithCredentials.post<SmartIdInitiateAuthDto>(
        apiRoutes.smartIdAuthInitiate,
        authRequest
      );

    return data;
  },

  async smartIdAuthComplete(authRequest: SmartIdInitiateAuthDto) {
    const { data } = await transportWithCredentials.post<{
      redirectUrl: string;
      secret: string;
    }>(apiRoutes.smartIdAuthComplete, authRequest);

    return data;
  },

  async smartIdSignInitiate(payload: {
    country: string;
    signingRequestId: string;
  }) {
    const { data } = await trackSigningTelemetryFor.post<SignInitiateDto>(
      apiRoutes.smartIdSignInitiate,
      payload,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async smartIdSignComplete(payload: {
    verificationCode: string;
    signatureValue: string;
    signingRequestId: string;
    containerId: string;
  }) {
    const { data } = await trackSigningTelemetryFor.post(
      apiRoutes.smartIdSignInitComplete,
      payload,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async eParakstsSignInitiate(payload: {
    signingRequestId: string;
    locale: string;
    callbackUrl: string;
  }) {
    const { data } = await trackSigningTelemetryFor.post(
      apiRoutes.eParakstsSignInitiate,
      payload,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async eParakstsSignComplete(queryParams: string, callbackUrl: string, signingRequestId: string) {
    const { data } = await trackSigningTelemetryFor.get(
      apiRoutes.eParakstsSignComplete +
      `${queryParams}&callbackUrl=${callbackUrl}&id=${signingRequestId}`,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async getSignedDocument(signingRequestId: string) {
    const url = apiRoutes.signedPdf(signingRequestId);

    const { data } = await trackSigningTelemetryFor.get<any>(url, {
      ...(await getHeadersWithJwtToken()),
      responseType: 'blob',
    });

    return data;
  },

  async getAuthInfo(signingRequestId: string) {
    const url = apiRoutes.requestAuthInfo(signingRequestId);

    const { data } = await Axios.get<ISigningAuthInfo>(
      url,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  async getSignedMethod(signingRequestId: string) {
    const url = apiRoutes.requestSignedMethod(signingRequestId);

    const { data } = await Axios.get<AuthMethodType>(
      url,
      await getHeadersWithJwtToken()
    );

    return data;
  },

  redirectTo(url: string, secretValue: string) {
    const form = document.createElement('form');
    form.style.display = 'none';
    form.action = apiRoutes.redirectTo;
    form.method = 'POST';

    var secret = document.createElement('input');
    secret.name = 'secret';
    secret.value = secretValue;

    var redirectUrl = document.createElement('input');
    redirectUrl.name = 'redirectUrl';
    redirectUrl.value = url;

    var createSigningContainer = document.createElement('input');
    createSigningContainer.name = 'createSigningContainer';
    createSigningContainer.value = 'false';

    form.appendChild(secret);
    form.appendChild(redirectUrl);
    form.appendChild(createSigningContainer);

    document.body.appendChild(form);
    form.submit();
  },

  redirectToUrl(url: string) {
    const form = document.createElement('form');
    form.style.display = 'none';
    form.action = url;
    form.method = 'GET';

    // var redirectUrl = document.createElement("input");
    // redirectUrl.name = "redirectUrl";
    // redirectUrl.value = url;

    // form.appendChild(secret);
    // form.appendChild(redirectUrl);

    document.body.appendChild(form);
    form.submit();
  },

  async getGoogleTagManager(key: string | undefined) {
    try {
      const url = apiRoutes.googleTagManager(key);

      const { data } = await Axios.get<string>(url);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getCertificateFunctionCode() {
    try {
      const url = apiRoutes.certificateFunctionCode;

      const { data } = await Axios.get<string>(url);
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default httpClient;
