import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function DefaultSpinner() {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status" />
      <span className="sr-only">{t("Loading")}</span>
    </div>
  );
}
