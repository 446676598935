import React from "react";
import { Dropdown, ButtonGroup, Button, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import appConfig, { LanguageType } from "../app.config";
import { userManager } from "../services/auth.service";
import { checkIfSignRelatedPage } from "../utils/helpers";

function Header() {

    // const [language, setlanguage] = React.useState("English");
    const { t, i18n } = useTranslation();

    function onLanguageSelect(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
    }

    function changeLanguage(lng: string) {
        i18n.changeLanguage(lng);
    }

    async function logout() {

        await userManager.clearStaleState();
        userManager.signoutRedirect()
    }

    function LanguagePicker() {
        return (
            <div>
                <Dropdown as={ButtonGroup} className="language-button" onSelect={changeLanguage}>
                    <Dropdown.Toggle split variant="outline-light" id="dropdown-split-basic" >
                        {/* {appConfig.languages[i18n.language as LanguageType]} */}
                        <span className="language-selector">
                            <img src={"/img/iconfinder/" + i18n.language + ".png"} alt={appConfig.languages[i18n.language as LanguageType] + " flag"} />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                        {Object.keys(appConfig.languages).map((key) =>
                            <Dropdown.Item eventKey={key} key={key}>
                                <div><div>{appConfig.languages[key as LanguageType]}</div></div>
                                <div><img src={"/img/iconfinder/" + key + ".png"} alt={appConfig.languages[key as LanguageType] + " flag"} /></div>
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    //console.warn(Object.keys(appConfig.languages), "langs")

    return (
        <nav className="navbar navbar-default navbar-fixed-top" id="topnavbar">

            <div className="container">
                <div className="navbar-header">
                    <a className="navbar-brand" href="/" onClick={onLanguageSelect}>
                        <div className={checkIfSignRelatedPage() ? 'logo signrelated' : 'logo'}></div>
                    </a>
                </div>
                <Navbar className="navbar-right">

                    <LanguagePicker />

                    {checkIfSignRelatedPage()
                        ? <div className="logout">
                            <Button
                                className="btn-logout-light"
                                onClick={logout}>
                                <span className="glyphicon glyphicon-log-out" /> {t("Logout")}
                            </Button>
                        </div>
                        : null
                    }
                </Navbar>
            </div>
        </nav>
    )
}

export default Header
