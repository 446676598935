import { userManager } from '../services/auth.service'
import { waitForLanguage } from './helpers'
import { appInsights } from '../appInsights'
import { User } from 'oidc-client'

let tokenInfo: Partial<User> = {}

export async function getHeadersWithJwtToken() {
    const {
        access_token,
        expires_at,
        profile,
    } = (await userManager.getUser()) || { access_token: '' }

    if (access_token && tokenInfo.access_token !== access_token) {
        tokenInfo = { expires_at, profile, access_token }

        appInsights.trackEvent({
            name: 'user got a token',
            properties: { expires_at, profile },
        })
    }

    return {
        headers: {
            Authorization: access_token && `Bearer ${access_token}`,
            'Accept-Language': await waitForLanguage(),
        },
    }
}
