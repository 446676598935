import { IDataLayer } from "../interfaces/i-data-layer"

interface ISnippets {
  id: string;
  dataLayer: IDataLayer | undefined;
}

const Snippets = {
  tags: function ({ id, dataLayer }: ISnippets) {

    if (!id) console.warn('GTM Id is undefined')

    //body
    const iframe = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
          height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`

    //head
    const script = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`

    const dataLayerVar = this.dataLayer(dataLayer)

    return {
      iframe,
      script,
      dataLayerVar
    }
  },
  dataLayer: function (dataLayer: IDataLayer | undefined) {
    return `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(${JSON.stringify(dataLayer)})`
  }
}

export default Snippets;