import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import appConfig from '../../../app.config';
import { logError, logEvent } from '../../../appInsights';

const onRequest = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  consoleLog(`[request] [${JSON.stringify(config)}]`);
  logEvent('UI-Signing-Request', { config: `${JSON.stringify(config)}` });
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${error.toJSON}]`);
  logError(error);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  consoleLog(`[response] [${JSON.stringify(response)}]`);
  logEvent('UI-Signing-Response', {
    url: response.config.url,
    status: response.status,
    data: response.data,
  });
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  consoleLog(`[response error] [${JSON.stringify(error)}]`);
  logError(error);

  return Promise.reject(error);
};

const logInConsole = appConfig.environment !== 'production';
const consoleLog = (message: string) => {
  if (logInConsole) console.log(message);
};

export function setupInterceptors(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);
  return instance;
}
