import React from 'react'
import { Col, Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getQueryParams } from '../../utils/helpers'
import appConfig from '../../app.config'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import httpClient from '../../services/httpClient'
import { appInsights } from '../../appInsights'

const checkLang = (language: string) => {
    if (language === 'ee') {
        return 'et'
    }
    if (language === 'ru') {
        return 'ru'
    }
    if (language !== 'ee' && language !== 'ru') {
        return 'en'
    }
}

interface ICardIdAuthProps {
    id: string
    onError(error: Error | undefined): void
    onSuccess(str: string): void
}

function IdCardContentBlock({ id, onError }: ICardIdAuthProps) {
    const { t } = useTranslation()
    const [isWaiting, setIsWaiting] = React.useState(false)
    const urlParams = React.useMemo(() => {
        let { ReturnUrl, SigningRequestId } = getQueryParams()
        return { ReturnUrl, SigningRequestId }
    }, [])

    async function tryIdCardAuth() {
        onError(undefined)
        setIsWaiting(true)

        try {
            let { ReturnUrl, SigningRequestId } = getQueryParams()

            const action = encodeURIComponent(
                `${appConfig.authorityBaseUrl}/apiauth/idcard/${
                    SigningRequestId || ''
                }`
            )

            const returnUrl = encodeURIComponent(
                ReturnUrl ||
                    `${window.location.protocol}//${
                        window.location.host
                    }/sign/${id ? id : ''}`
            )
            const initialUrl = encodeURIComponent(window.location.href)

            const code = await httpClient.getCertificateFunctionCode()
            window.location.replace(
                `${appConfig.certificateRequesterBaseUrl}/api/CertificateFunction?code=${code}&returnUrl=${returnUrl}&action=${action}&initialUrl=${initialUrl}`
            )
        } catch (exception) {
            appInsights.trackException({ exception })
            onError(exception)
        }
        setIsWaiting(false)
    }

    if (isWaiting) {
        return (
            <Container className="IdCard">
                <Col xs={12}>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    return (
        <Container className="IdCard">
            <IdCardHelpText />

            <Col xs={12}>
                <Button onClick={tryIdCardAuth} variant="primary">
                    {t('Proceed')}
                </Button>
            </Col>
        </Container>
    )
}

export function IdCardHelpText() {
    const { t, i18n } = useTranslation()
    const [lang, setLang] = React.useState<string | undefined>()
    const line1 = t(
        'Before using your ID-Card, please make sure that your ID-Card is inserted into your card reader'
    )
    const line2 = t('If the login fails please check the possible solutions')
    const line3 = t('at id_ee help centre')

    React.useEffect(() => {
        setLang(checkLang(i18n.language))
    }, [i18n.language])

    return (
        <Col xs={12} className="idCardHelpText">
            <p>{line1}</p>
            <p>
                {line2}{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.id.ee/?lang=${lang}&id=30466`}
                >
                    {line3}
                </a>
            </p>
        </Col>
    )
}

export default IdCardContentBlock
