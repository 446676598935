import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import httpClient from '../../services/httpClient'
import { getQueryParams } from '../../utils/helpers'

const payseraInitiate = async (id: string, bank: string) => {}

const buttons = [
    [
        {
            dataBank: 'sampo',
            imgSrc: '/img/DanskeBank.png',
            altText: 'Danske Bank',
        },
        {
            dataBank: 'nordealt',
            imgSrc: '/img/LuminorNordea.png',
            altText: 'Luminor',
        },
        {
            dataBank: 'sb',
            imgSrc: '/img/SiauliuBankas.png',
            altText: 'JSC bank &quot;Šiaulių bankas&quot;',
        },
    ],
    [
        {
            dataBank: 'nord',
            imgSrc: '/img/LuminorDNB.png',
            altText: 'Luminor | DNB',
        },
        {
            dataBank: 'vb2',
            imgSrc: '/img/SEB.png',
            altText: 'SEB',
        },
        {
            dataBank: 'mb',
            imgSrc: '/img/MedicinosBankas.png',
            altText: 'JSC bank &quot;Medicinos Bankas&quot;',
        },
    ],
    [
        {
            dataBank: 'parex',
            imgSrc: '/img/Citadele.png',
            altText: 'Citadele',
        },
        {
            dataBank: 'hanza',
            imgSrc: '/img/Swedbank.png',
            altText: 'Swedbank',
        },
        {
            dataBank: 'lku',
            imgSrc: '/img/LKU.png',
            altText: 'LKU',
        },
    ],
]

interface PayseraContentBlockProps {
    id: string
    onError(error: Error | undefined): void
    onSuccess(str: string): void
}

export const PayseraContentBlock: FC<PayseraContentBlockProps> = ({ id }) => {
    const { t } = useTranslation()
    const urlParams = React.useMemo(() => {
        let { ReturnUrl, SigningRequestId } = getQueryParams()
        return { ReturnUrl, SigningRequestId }
    }, [])

    const initiatePaysera =
        (bank: string) => async (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            const { ReturnUrl, SigningRequestId } = urlParams
            const data = await httpClient.payseraInitiate(
                SigningRequestId ?? '',
                bank,
                'auth'
            )

            window.location.replace(data.url)
        }

    return (
        <Container className="PayseraSign">
            <Row>
                <h3 className="text-center">
                    {t(
                        "Please select the bank you'd like to conduct the 1 cent transfer with"
                    )}
                    :
                </h3>
            </Row>
            <Row>
                <Col>
                    {buttons.map((row, i) => (
                        <Row key={i} className="mt-sm-3 text-center">
                            {row.map((bank) => (
                                <div
                                    className="col-sm-4 my-3 mb-sm-5 text-center"
                                    key={bank.dataBank}
                                >
                                    <a
                                        href="/"
                                        onClick={initiatePaysera(bank.dataBank)}
                                    >
                                        <img
                                            className="paysera-bank"
                                            src={bank.imgSrc}
                                            alt={bank.altText}
                                            data-bank={bank.dataBank}
                                        />
                                    </a>
                                </div>
                            ))}
                        </Row>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}
