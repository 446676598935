import React from 'react'
import {
    Container,
    Button,
    Col,
} from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import httpClient from '../../services/httpClient'
import { appRoutes } from '../../constants/routes'
import { getQueryParams } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { appInsights } from '../../appInsights'
import { appConstants } from '../../constants/appConstants'

interface EParakstsLoginBlockProps {
    id: string
    onSuccess(str: string): void
    onError(error: Error | undefined): void
}

function EParakstsContentBlock({ id, onError }: EParakstsLoginBlockProps) {
    const [isWaiting, setIsWaiting] = React.useState(false)
    const { t, i18n } = useTranslation()
    
    async function doEParakstsAuth() {
        onError(undefined)

        setIsWaiting(true)

        try {
            const { SigningRequestId, ReturnUrl } = getQueryParams()
            const signingRequestId = id || SigningRequestId || ''
            const selectedLanguage = i18n.language
            sessionStorage.setItem(appConstants.signingRequestId, signingRequestId)

            const data = await httpClient.eParakstsAuthInit({
                signingRequestId,
                selectedLanguage,
                returnUrl: encodeURIComponent(ReturnUrl),
                callbackUrl: window.location.origin + appRoutes.eParakstsCallback,
            })

            window.location.href = data

        } catch (exception) {
            appInsights.trackException({ exception })
            onError(exception)
            setIsWaiting(false)
        }
    }

    if (isWaiting) {
        return (
            <Container className="eParaksts">
                <Col xs={12}>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    return (
        <Container className="eParaksts">
            <Col xs={12} className="documentNumber mx-auto">
                <p>{t('Please click the proceed button to authenticate')}</p>
            </Col>

            <Col xs={12} className="mx-auto">
                <Col xs={12}>
                    
                </Col>
            </Col>

            <Col xs={12} className="mx-auto mt-4">
                <Button variant="primary" onClick={doEParakstsAuth}>
                    {t('Proceed')}
                </Button>
            </Col>
        </Container>
    )
}

export default EParakstsContentBlock

