export function validateLatvianSsn(idNumber: string) {
    if (!idNumber) {
        return false;
    }

    if (idNumber.length < 7) {
        return false;
    }

    if (idNumber[6] === '-') {
        idNumber = idNumber.substr(0, 6).concat(idNumber.substr(7));
    }

    if (idNumber.length !== 11) {
        return false;
    }

    const centuryDigit = parseInt(idNumber.substr(6, 1));

    if (isNaN(centuryDigit)) return false;
    
    var birthDateString = "";
    switch (centuryDigit) {
        case 0:
            birthDateString += "18";
            break;
        case 1:
            birthDateString += "19";
            break;
        case 2:
            birthDateString += "20";
            break;
    }
    
    birthDateString += idNumber.substr(4, 2);

    birthDateString += "-";

    birthDateString += idNumber.substr(2, 2);

    birthDateString += "-";

    birthDateString += idNumber.substr(0, 2);

    const dateOfBirth = Date.parse(birthDateString);

    if (isNaN(dateOfBirth)) return false;

    var weights = [ 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
    var checksum = 1101;
    let digit;
    for (var i = 0; i < weights.length; i++) {
        
        digit = parseInt(idNumber[i].toString());

        if (isNaN(digit)) return false;
        checksum -= digit*weights[i];
    }
    digit = parseInt(idNumber[10].toString());

    if (isNaN(digit)) return false;

    const isValid = digit === checksum%11;

    return isValid;
}