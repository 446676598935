import React from "react";
import { Container, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function FailedSign() {

    const { t } = useTranslation();

    return (
        <Container className="errorPage">
            <Alert variant="danger">
                {t("Failed to fetch document to sign")}
            </Alert>
        </Container>
    )
}

export default FailedSign;