import React, { useContext } from "react";
import { Row, Card, Col, Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import httpClient from "../../services/httpClient";
import { downloadBlob } from "../../utils/helpers";
import SigningContext from "../../contexts/SigningContext";
import appConfig from "../../app.config";
import { documentTypes } from "../../constants/documentTypes";
import SigningStatus from "../../constants/signingStatuses";
import TagManager from "../TagManager";
import { ITagManagerArgs } from "../../interfaces/i-tag-manager-args";
import { IDataLayer } from "../../interfaces/i-data-layer";
import { GtmEvents } from "../../constants/gtmEvents";
import AuthMethodType from "../../constants/signingMethods";

function SigningCompleteBlock() {

    const { t } = useTranslation();
    const {
        signingRequest,
        setError
    } = useContext(SigningContext);

    const { country } = appConfig;

    const isConsent = signingRequest?.documentType === documentTypes.consent;
    const isConsentSignLt = signingRequest?.status === SigningStatus.signed && isConsent && country === "lt";
    
    const trigger = React.useCallback(async () => {
        const signMethod = localStorage.getItem('signMethod');
        const tagManagerArgs = {
            dataLayer: {
                applicationID: signingRequest?.applicationId,
                signingMethod: signMethod,
                authenticationMethod: undefined,
                event: GtmEvents.SigningSuccess,
            } as IDataLayer
        } as ITagManagerArgs;

        TagManager.dataLayer(tagManagerArgs);

        if (signingRequest?.documentType === documentTypes.consent &&
            signMethod === AuthMethodType[AuthMethodType.Paysera]) {
            const tagManagerArgs = {
                dataLayer: {
                    applicationID: signingRequest?.applicationId,
                    signingMethod: signMethod,
                    authenticationMethod: undefined,
                    event: GtmEvents.ConsentSuccess,
                } as IDataLayer
            } as ITagManagerArgs;

            TagManager.dataLayer(tagManagerArgs);
        }
    }, [signingRequest]);

    React.useEffect(() => {
        trigger();
    }, [trigger])

    async function downloadSignedDocument() {

        if (!signingRequest) {
            setError(new Error("No data"));
            return;
        }

        const signMethod = localStorage.getItem('signMethod');

        const blob = await httpClient.getSignedDocument(signingRequest.id);

        const extension = signMethod === AuthMethodType[AuthMethodType.Paysera] ? "pdf"
            : country === "lt"
                ? "adoc"
                : country === "lv"
                    ? "asice"
                    : "bdoc";

        const fileName = `TF Bank ${isConsent ? t("consent") : t("promissoryNote")
            }_${signingRequest.applicationId}.${extension}`;

        downloadBlob(blob, fileName);
    }

    return (
        <Row className="signingCompleteBlock">
            <Card>
                {isConsentSignLt &&
                    <Card.Header>
                        <h2>{t("Your identity has been successfully verified!")}</h2>
                    </Card.Header>
                }
                {(isConsent !== true || country !== "lt") &&
                    < Card.Header >
                        <h2>{t("Esigning was successful")}</h2>
                    </Card.Header>}
                <Card.Body>
                    <Container>
                        {signingRequest?.status === SigningStatus.signed && isConsent &&
                            <Col xs={12} className="my-5">
                                <p className="text-center">{t("In the near future, TF Bank will contact you about the next steps to obtain a loan.")}</p>
                            </Col>
                        }
                        {(isConsent !== true || country !== "lt") &&
                            <Col xs={12} className="my-5">
                                <p className="text-center">{t("Please download your esigned contract by pressing the button below")}</p>
                            </Col>
                        }
                        <Col xs={12} className="my-5">
                            {isConsentSignLt &&
                                <Button
                                    variant="outline-secondary"
                                    className="mb-4"
                                    onClick={downloadSignedDocument}>
                                    <span className="glyphicon glyphicon-download-alt mr-2" />
                                    {t("Download TF Bank Privacy Policy")}
                                </Button>
                            }
                            {(isConsent !== true || country !== "lt") &&
                                <Button
                                    variant="outline-secondary"
                                    className="mb-4"
                                    onClick={downloadSignedDocument}>
                                    <span className="glyphicon glyphicon-download-alt mr-2" />
                                    {t("Download esigned document")}
                                </Button>}
                        </Col>
                        <Col xs={12} className="backButton">
                            {(isConsent !== true || country !== "lt") &&
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        window.location.replace(signingRequest ? signingRequest.redirectUrl : "")
                                    }}>
                                    {t("Back to merchant")}
                                </Button>}
                        </Col>
                    </Container>
                </Card.Body>
            </Card>
        </Row >
    )
}

export default SigningCompleteBlock;
