import { parsePhoneNumberFromString, PhoneNumber, CountryCode } from "libphonenumber-js";

export const validatePhoneNumber = (value: string, countryCode: CountryCode) => {

    let phone: PhoneNumber | undefined;
    let isValid = true;

    if (countryCode) {

        phone = parsePhoneNumberFromString(value, countryCode);
        isValid = phone
            ? phone.isPossible() && phone.country?.toLowerCase() === countryCode
            : false;

        if (isValid) return isValid;
    }

    phone = phone ?? parsePhoneNumberFromString(value);
    isValid = phone
        ? phone.isPossible() && phone.country?.toLowerCase() === countryCode
        : false;

    return isValid;
}