import React, { useEffect, useState, FC } from 'react'
import { Card, Alert, Spinner, Container, Button } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router'
import { ISignRouteParam } from '../../interfaces/i-signing-page'
import httpClient from '../../services/httpClient'
import { appRoutes } from '../../constants/routes'
import { useTranslation } from 'react-i18next'
import { appConstants } from '../../constants/appConstants'

export const EParakstsAuthCallback: FC<RouteComponentProps<ISignRouteParam>> = ({
    match,
}) => {
    const [error, setError] = useState<Error>()
    const search = window.location.search
    const params = new URLSearchParams(search)
    const signingRequestId = sessionStorage.getItem(appConstants.signingRequestId)

    

    const { t } = useTranslation();


    async function signin() {
        
        try {
            if (window.location.search.indexOf('error') !== -1) {
                const errorMsg = params.get('error')
                setError(new Error(errorMsg ? errorMsg : ''))
            } else if (window.location.search.indexOf('code') > -1) {
                const authResult =
                    await httpClient.eParakstsResultGet(search, window.location.origin + appRoutes.eParakstsCallback)
                
                httpClient.redirectTo(
                    authResult.returnUrl ?? window.location.origin + appRoutes.signRoot,
                    authResult.secret
                )
            }
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        signin()
    }, [signingRequestId])

    if (error) {
        return (
            <Container id="SignPage">
                <Card>
                    <Card.Title>
                        <Alert variant="danger">{t(error.message)}</Alert>
                    </Card.Title>
                </Card>
                <div className='contentBlock'>
                    <Button
                        variant="primary"
                        onClick={() => {
                            window.location.replace(window.location.origin + appRoutes.signRoot + "/" + signingRequestId)
                        }}>
                        {t("Back")}
                    </Button>
                </div>
                
            </Container>
        )
    }

    return (
        <Container id="SignPage">
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        </Container>
    )
}
