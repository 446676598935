import React from 'react'
import { apiRoutes } from '../constants/routes'
import { Alert } from 'react-bootstrap'
import { DefaultSpinner } from '../components/pageBlocks/Spinners'
import SigningStatus from '../constants/signingStatuses'
import { IPdf } from '../interfaces/i-pdf'
import { isPdf } from '../utils/helpers'
import { getHeadersWithJwtToken } from '../utils/auth-util'
import {
    PDFDocumentProxy,
    GlobalWorkerOptions,
    version,
    getDocument,
} from 'pdfjs-dist'
import { useTranslation } from 'react-i18next'
import { appInsights } from '../appInsights'

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`

interface IPdfViewerProps {
    id: string
    status: SigningStatus | undefined
    pdf: Error | PDFDocumentProxy | undefined
    setPdf: React.Dispatch<
        React.SetStateAction<Error | PDFDocumentProxy | undefined>
    >
}

const zoomScale = 0.25
const initialScale = 1.25

function PdfViewer({ id, status, pdf, setPdf }: IPdfViewerProps) {
    const { t } = useTranslation()
    const [scale, setScale] = React.useState(initialScale)
    const [error, setError] = React.useState<Error | undefined>()
    const [currentPage, setCurrentPage] = React.useState(1)
    const isMounted = React.useRef(true)

    const getPdfFile = React.useCallback(async () => {
        try {
            const url = apiRoutes.originalPdf(id)
            const headerObject = await getHeadersWithJwtToken()
            const pdfObject = await getDocument({
                url,
                httpHeaders: headerObject.headers,
            }).promise

            return pdfObject
        } catch (exception) {
            appInsights.trackException({ exception })
            setError(new Error(exception))
        }
    }, [id])

    React.useEffect(() => {
        if (!status && status !== SigningStatus.unsigned) return

        getPdfFile().then((pdfFile) => {
            if (isMounted.current) {
                setPdf(pdfFile)
                setCurrentPage(1)
                setScale(initialScale)
                setPdfPage(pdfFile, 1, initialScale)
            }
        })

        return () => {
            isMounted.current = false
        }
    }, [status, getPdfFile, setPdf])

    function goBack() {
        if (!isPdf(pdf)) return

        const newCurrentPage = currentPage - 1

        if (newCurrentPage < 1) return

        setCurrentPage(newCurrentPage)
        setPdfPage(pdf, newCurrentPage, scale)
    }

    function goNext() {
        if (!isPdf(pdf)) return

        const newCurrentPage = currentPage + 1

        let pdfDocProxy = pdf as PDFDocumentProxy

        if (newCurrentPage > pdfDocProxy.numPages) return

        setCurrentPage(newCurrentPage)
        setPdfPage(pdf, newCurrentPage, scale)
    }

    function zoomin() {
        if (!isPdf(pdf)) return

        const newScale = scale + zoomScale

        setScale(newScale)
        setPdfPage(pdf, currentPage, newScale)
    }

    function zoomout() {
        if (!isPdf(pdf) || scale <= zoomScale) return

        const newScale = scale - zoomScale

        setScale(newScale)
        setPdfPage(pdf, currentPage, newScale)
    }

    function PdfControls() {
        return (
            <div className="divPdfControls">
                <button id="prev" onClick={goBack}>
                    <i className="glyphicon glyphicon-chevron-up"></i>
                </button>

                <div>
                    {isPdf(pdf) && currentPage}/
                    {isPdf(pdf) && (pdf as PDFDocumentProxy).numPages}
                </div>

                <button id="next" className="mr-4" onClick={goNext}>
                    <i className="glyphicon glyphicon-chevron-down"></i>
                </button>

                <button
                    id="zoomin"
                    className="mr-1 d-none d-md-flex"
                    onClick={zoomin}
                >
                    <i className="glyphicon glyphicon-zoom-in"></i>
                </button>

                <button
                    id="zoomout"
                    className="d-none d-md-flex"
                    onClick={zoomout}
                >
                    <i className="glyphicon glyphicon-zoom-out"></i>
                </button>
            </div>
        )
    }

    return (
        <div id="canvas-container">
            {PdfControls()}

            {error ? (
                <Alert variant="danger">{t('MissingPDFException')}</Alert>
            ) : pdf ? (
                <canvas id="canvasPdf" />
            ) : (
                <DefaultSpinner />
            )}

            {pdf && PdfControls()}
        </div>
    )
}

async function setPdfPage(pdf: IPdf, pageNumber: number = 1, scale = 2.5) {
    if (!isPdf(pdf)) return

    console.warn(scale)
    const pdfProxy = pdf as PDFDocumentProxy
    const page = await pdfProxy.getPage(pageNumber)

    const viewport = page.getViewport({ scale })
    const canvas = document.getElementById('canvasPdf') as HTMLCanvasElement
    canvas.height = viewport.height
    canvas.width = viewport.width
    const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D

    const renderContext = { viewport, canvasContext }

    page.render(renderContext)
}

export default PdfViewer
