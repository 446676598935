export type LanguageType = 'en' | 'ee' | 'lt' | 'ru' | 'lv'

interface IAppConfig {
    certificateRequesterBaseUrl: string

    country: 'ee' | 'lt' | 'lv'

    environment: string

    authorityBaseUrl: string

    apiBaseUrl: string

    languages: {
        [key in LanguageType]?: string
    }

    instrumentationKey: string
}

const appConfig: IAppConfig = {
    certificateRequesterBaseUrl: process.env
        .REACT_APP_CERTIFICATE_REQUESTER_URL as string,

    country: process.env.REACT_APP_COUNTRY as 'ee' | 'lt' | 'lv',

    environment: process.env.REACT_APP_ENVIRONMENT as string,

    authorityBaseUrl: process.env.REACT_APP_AUTHORITY_BASE_URL as string,

    apiBaseUrl: process.env.REACT_APP_BASE_API_URL as string,

    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY as string,

    languages:
        process.env.REACT_APP_COUNTRY === 'ee'
            ? {
                  ee: 'Estonian',
                  ru: 'Russian',
                  en: 'English',
              }
            : process.env.REACT_APP_COUNTRY === 'lt'
            ? {
                  lt: 'Lietuvių',
                  en: 'English',
              }
            : {
                  lv: 'Latviski',
                  en: 'English',
              },
}

export default appConfig
