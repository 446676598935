
import { validateEstoniaSsn } from "./estonia/esonian-ssn";
import { validateLithuaniaSsn } from "./lithuania/lithuania-ssn";
import { validateLatvianSsn } from "./latvia/latvia-ssn";
import appConfig from "../../app.config";

export function validateSsn(value: string) {

    let isValid = true;
    switch (appConfig.country) {
        case "ee":

            isValid = validateEstoniaSsn(value);
            return isValid;

        case "lt":

            const object = validateLithuaniaSsn(value);
            isValid = typeof object === "object"
                ? object.isValid
                : Boolean(object);
            return isValid;

        case "lv":

            isValid = validateLatvianSsn(value);
            return isValid;

        default:
            return isValid;
    }
}