import React, { FormEvent } from 'react'
import {
    Container,
    Col,
    Button,
    FormControl,
    Row,
    Card,
    Form,
} from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import { IMobileIdAuthResponse } from '../../interfaces/i-mobileid'
import { useTranslation } from 'react-i18next'
import httpClient from '../../services/httpClient'
import { getQueryParams, getPhoneForCountry } from '../../utils/helpers'
import { appRoutes } from '../../constants/routes'
import appConfig from '../../app.config'
import { validatePhoneNumber, validateSsn } from '../../utils/validation.helper'
import { CountryCode } from 'libphonenumber-js'
import { appInsights } from '../../appInsights'

interface IMobileIdAuthProps {
    id: string
    onError(error: Error | undefined): void
    onSuccess(str: string): void
}

function MobileIdAuthBlock({ id, onError }: IMobileIdAuthProps) {
    const { t } = useTranslation()
    const [mobileNumber, setMobileNumber] = React.useState(
        getPhoneForCountry(appConfig.country)
    )
    const [mobileNumberIsValid, setMobileNumberIsValid] = React.useState(true)
    const [personalNumber, setPersonalNumber] = React.useState('')
    const [personalNumberIsValid, setPersonalNumberIsValid] =
        React.useState(true)
    const [isWaiting, setIsWaiting] = React.useState(false)
    const [mobileIdAuthData, setMobileIdAuthData] =
        React.useState<IMobileIdAuthResponse>()
    const urlParams = React.useMemo(() => {
        let { ReturnUrl, SigningRequestId } = getQueryParams()
        return { ReturnUrl, SigningRequestId }
    }, [])

    function onMobileNumberChange(e: React.FormEvent<HTMLInputElement>) {
        setMobileNumber(e.currentTarget.value as string)
    }

    function onPersonalNumberChange(e: FormEvent<HTMLInputElement>) {
        setPersonalNumber(e.currentTarget.value as string)
    }

    function validatePersonalNumber() {
        const isValid = validateSsn(personalNumber)
        setPersonalNumberIsValid(isValid)
        return isValid
    }

    function validateMobileNumber() {
        const isValid = validatePhoneNumber(
            mobileNumber,
            appConfig.country as CountryCode
        )
        setMobileNumberIsValid(isValid)

        return isValid
    }

    function validateAll() {
        const isValidSSN = validatePersonalNumber()
        const isValidMobileNumber = validateMobileNumber()
        return isValidSSN && isValidMobileNumber
    }

    async function mobileIdInitiate() {
        onError(undefined)

        try {
            const { ReturnUrl, SigningRequestId } = urlParams
            const isValid = SigningRequestId
                ? validateMobileNumber()
                : validateAll()

            if (!isValid) {
                return
            }

            setIsWaiting(true)

            const data = await httpClient.mobileIdAuthInitiate(
                mobileNumber,
                id || SigningRequestId || '',
                personalNumber,
                ReturnUrl,
                appConfig.country
            )
            setMobileIdAuthData(data)
            setIsWaiting(false)

            const { redirectUrl, secret } =
                await httpClient.mobileIdAuthComplete(data && data.userData)
            httpClient.redirectTo(
                redirectUrl || window.location.origin + appRoutes.signRoot,
                secret
            )
        } catch (exception) {
            appInsights.trackException({ exception })
            onError(exception)
        }

        setIsWaiting(false)
    }

    if (isWaiting) {
        return (
            <Container>
                <Col xs={12}>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    if (mobileIdAuthData && mobileIdAuthData.userData) {
        return (
            <Container>
                <Card>
                    <Card.Body className="verification-container">
                        <Row className="mx-auto">
                            <Col xs={12}>
                                <span>
                                    {t(
                                        'Make sure that the verification code sent to your mobile matches and enter PIN1'
                                    )}
                                </span>
                                {/* <span>{t("matches the code on the screen, and then enter Mobile-ID Pin1")}</span> */}
                                <h1>{mobileIdAuthData.challengeId}</h1>
                            </Col>
                        </Row>
                        <DefaultSpinner />
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    return (
        <Container className="mobileId">
            <Row className="mx-auto">
                <Col xs={12}>
                    <span>
                        {t(
                            'Please enter your phone number that is connected to Mobile-ID'
                        )}{' '}
                    </span>
                    <span>{t('Then press proceed button to login')} </span>
                    <span>{t('Make sure you have your cellphone nearby')}</span>
                </Col>

                {!urlParams.SigningRequestId && (
                    <Col xs={12}>
                        <FormControl
                            placeholder={t('Personal Number')}
                            className="mt-2"
                            name="personalNumber"
                            id="personalNumber"
                            onChange={onPersonalNumberChange}
                            onBlur={validatePersonalNumber}
                            isInvalid={!personalNumberIsValid}
                            defaultValue={personalNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('please enter the correct personal number')}
                        </Form.Control.Feedback>
                    </Col>
                )}

                <Col xs={12}>
                    <FormControl
                        placeholder={t('Mobile phone')}
                        className="mt-2"
                        name="mobileNumber"
                        id="mobileNumber"
                        onChange={onMobileNumberChange}
                        onBlur={validateMobileNumber}
                        isInvalid={!mobileNumberIsValid}
                        defaultValue={mobileNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                        {t('please enter the correct phone number')}
                    </Form.Control.Feedback>
                </Col>

                <Col xs={12} className="mt-4">
                    <Button variant="primary" onClick={mobileIdInitiate}>
                        {t('Proceed')}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default MobileIdAuthBlock
