import React, { useContext } from 'react'
import {
    Col,
    Row,
    Button,
    FormControl,
    Container,
    Card,
    Form,
} from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import { useTranslation } from 'react-i18next'
import httpClient from '../../services/httpClient'
import SigningContext from '../../contexts/SigningContext'
import { getPhoneForCountry } from '../../utils/helpers'
import appConfig from '../../app.config'
import { validatePhoneNumber } from '../../utils/validation.helper'
import { CountryCode } from 'libphonenumber-js'
import { appInsights } from '../../appInsights'

function MobileIdSignBlock() {
    const { t } = useTranslation()
    const [mobileNumber, setMobileNumber] = React.useState(
        getPhoneForCountry(appConfig.country)
    )
    const [mobileNumberIsValid, setMobileNumberIsValid] = React.useState(true)
    const [isWaiting, setIsWaiting] = React.useState(false)
    const [code, setVerificationCode] = React.useState('')

    const { signingRequest, user, setSigned, setError } =
        useContext(SigningContext)

    const id = signingRequest?.id ?? ''

    function onMobileNumberChange(e: React.FormEvent<any>) {
        setMobileNumber(e.currentTarget.value as string)
    }

    async function mobileIdInitiate() {
        setError(undefined)

        if (user?.profile?.AuthMethodType !== 'MobileId') {
            const isValid = validateMobileNumber()
            if (!isValid) return
        }

        try {
            setIsWaiting(true)
            const data = await httpClient.mobileIdSignInitiate({
                signingRequestId: id,
                phoneNumber: mobileNumber || '',
            })

            const verification = data.verificationCode
            setVerificationCode(verification)
            setIsWaiting(false)

            await httpClient.mobileIdSignComplete({
                verificationCode: verification || '',
                signatureValue: data.signatureValue || '',
                signingRequestId: id,
                containerId: data.containerID || '',
            })

            setSigned()
        } catch (exception) {
            appInsights.trackException({ exception })
            setError(exception)
        }

        setIsWaiting(false)
    }

    if (isWaiting) {
        return (
            <Container id="MobileId">
                <Col xs={12}>
                    <DefaultSpinner />
                </Col>
            </Container>
        )
    }

    function validateMobileNumber() {
        const isValid = validatePhoneNumber(
            mobileNumber,
            appConfig.country as CountryCode
        )
        setMobileNumberIsValid(isValid)

        return isValid
    }

    if (code) {
        return (
            <Container id="MobileId">
                <Card>
                    <Card.Body className="verification-container">
                        <Row className="mx-auto">
                            <Col xs={12}>
                                <p>
                                    {t(
                                        'Make sure that the verification code sent to your mobile matches and enter PIN2'
                                    )}
                                </p>
                                <h1>{code}</h1>
                            </Col>
                        </Row>
                        <DefaultSpinner />
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    return (
        <Container id="MobileId">
            <Col xs={12}>
                {user?.profile?.AuthMethodType === 'MobileId' ? (
                    <>
                        <p>{t('Press proceed button to sign')} </p>
                        <p>{t('Make sure you have your cellphone nearby')}</p>
                    </>
                ) : (
                    <>
                        <p>
                            {t(
                                'Please enter your phone number that is connected to Mobile-ID'
                            )}
                        </p>
                        <p>{t('Then press proceed button to sign')} </p>
                        <p>{t('Make sure you have your cellphone nearby')}</p>
                        <FormControl
                            placeholder={t('Phone number')}
                            className="mt-4"
                            name="mobileNumber"
                            id="mobileNumber"
                            onChange={onMobileNumberChange}
                            onBlur={validateMobileNumber}
                            isInvalid={!mobileNumberIsValid}
                            defaultValue={mobileNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('please enter the correct phone number')}
                        </Form.Control.Feedback>
                    </>
                )}
            </Col>
            <Col xs={12} className="mt-4">
                <Button variant="primary" onClick={mobileIdInitiate}>
                    {t('Proceed')}
                </Button>
            </Col>
        </Container>
    )
}

export default MobileIdSignBlock
