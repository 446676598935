import React from 'react'
import { getClientSettings } from '../../services/auth.service'
import { UserManager } from 'oidc-client'
import { RouteComponentProps } from 'react-router'
import { appRoutes } from '../../constants/routes'
import { Container, Alert } from 'react-bootstrap'
import { DefaultSpinner } from '../pageBlocks/Spinners'
import { appConstants } from '../../constants/appConstants'
import { appInsights } from '../../appInsights'

function CallbackPage({ history }: RouteComponentProps<any>) {
    const [error, setError] = React.useState<Error>()

    const signin = React.useCallback(async () => {
        const signingRequestId = localStorage.getItem(
            appConstants.signingRequestId
        )

        if (signingRequestId) {
            localStorage.removeItem(appConstants.signingRequestId)
        }

        const userManager = new UserManager(getClientSettings('query'))

        try {
            await userManager.signinCallback()

            history.push(`${appRoutes.signRoot}/${signingRequestId ? signingRequestId + '/authenticated' : ''}`)
        } catch (exception) {
            appInsights.trackException({ exception })
            setError(exception)
        }
    }, [history])

    React.useEffect(() => {
        signin()
    }, [signin])

    return (
        <Container className="callbackPage">
            {error ? (
                <Alert
                    variant="danger"
                    dismissible
                    onClose={() => {
                        setError(undefined)
                        window.location.replace(
                            `${window.location.origin + appRoutes.logout}`
                        )
                    }}
                >
                    {error.message}
                </Alert>
            ) : (
                <DefaultSpinner />
            )}
        </Container>
    )
}

export default CallbackPage
