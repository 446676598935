import React, { useEffect, useState, FC } from 'react'
import { Card, Alert, Spinner, Container } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router'
import SigningStatus from '../../constants/signingStatuses'
import { ISignRouteParam } from '../../interfaces/i-signing-page'
import { userManager } from '../../services/auth.service'
import httpClient from '../../services/httpClient'

const checkSigningStatus = async (id: string) => {
    while (true) {
        const statusResponse = await httpClient.payseraStatus(id)
        if (
            statusResponse.status === SigningStatus.signed ||
            statusResponse.status === SigningStatus.authenticated
        ) {
            await userManager.clearStaleState()
            await userManager.signinRedirect({
                extraQueryParams: {
                    SigningRequestId: id,
                    redirect_to: `${window.location.origin}/sign/${id}`,
                },
            })
            return
        }
        if (statusResponse.status === SigningStatus.failed) {
            throw new Error('paysera failed')
        }
        await new Promise((resolve) => setTimeout(resolve, 3000))
    }
}

export const PayseraAuthCallback: FC<RouteComponentProps<ISignRouteParam>> = ({
    match,
}) => {
    const [error, setError] = useState<Error>()
    const signingRequestId = match.params.id

    useEffect(() => {
        httpClient
            .payseraComplete(signingRequestId)
            .then(() => checkSigningStatus(signingRequestId))
            .catch(setError)
    }, [signingRequestId])

    if (error) {
        return (
            <Container id="SignPage">
                <Card>
                    <Card.Title>
                        <Alert variant="danger">{error.message}</Alert>
                    </Card.Title>
                </Card>
            </Container>
        )
    }

    return (
        <Container id="SignPage">
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        </Container>
    )
}
