import React, {
    createContext,
    PropsWithChildren,
    Context,
    useState,
    useCallback,
} from 'react'
import { ISigningRequest } from '../interfaces/i-signing-request'
import { appConstants } from '../constants/appConstants'
import { userManager } from '../services/auth.service'
import SigningStage from '../constants/signingStages'
import httpClient from '../services/httpClient'
import axios, { AxiosError } from 'axios'
import { getErrorWithFullMessage } from '../utils/helpers'
import SigningStatus from '../constants/signingStatuses'
import payseraStatuses from '../constants/payseraStatuses'
import { User } from 'oidc-client'
import queryString from 'query-string'
import TagManager from '../components/TagManager'
import { ITagManagerArgs } from '../interfaces/i-tag-manager-args'
import { IDataLayer } from '../interfaces/i-data-layer'
import { GtmEvents } from '../constants/gtmEvents'
import { documentTypes } from '../constants/documentTypes'
import AuthMethodType from '../constants/signingMethods'
import { appInsights } from '../appInsights'

interface SigningContext {
    signingRequest: ISigningRequest | undefined
    signingStage: SigningStage
    error: Error | undefined
    signingStatus: string
    user: User | null
    fetchSigningRequest(id: string, status: string): void
    setError(e: Error | undefined): void
    setSigned(justSigned?: boolean): void
    setSigningStage(stage: SigningStage): void
    setSigningStatus(status: string): void
}

const SigningContext: Context<SigningContext> = createContext<SigningContext>({
    signingRequest: undefined,
    signingStage: SigningStage.default,
    error: undefined,
    signingStatus: '',
    user: null,
    fetchSigningRequest: () => {},
    setError: () => {},
    setSigned: () => {},
    setSigningStage: () => {},
    setSigningStatus: () => {},
})

const redirectToLogin = async (id: string, style?: string) => {
    localStorage.setItem(appConstants.signingRequestId, id)
    await userManager.clearStaleState()

    userManager.signinRedirect({
        extraQueryParams: {
            SigningRequestId: id,
            style: style,
        },
    })
}

const checkAuth = async (id: string, style?: string) => {
    const user = await userManager.getUser()

    if (!Boolean(user)) {
        await redirectToLogin(id, style)
        return null
    }

    return user
}

export function SigningContextProvider({ children }: PropsWithChildren<any>) {
    const [signingRequest, setSigningRequest] = useState<
        ISigningRequest | undefined
    >()
    const [user, setUser] = useState<User | null>(null)
    const [error, setError] = React.useState<Error>()
    const [signingStage, setSigningStage] = React.useState<SigningStage>(
        SigningStage.default
    )
    const [signingStatus, setSigningStatus] = React.useState('')
    const [style, setStyle] = useState(sessionStorage.getItem('style')! ?? '')
    const autoclose = signingRequest?.autoclose ?? false
    const redirectUrl = signingRequest?.redirectUrl ?? ''

    const getStyle = React.useCallback(async () => {
        const parsedRedirectUrl = queryString.parseUrl(
            window.location.search.toLowerCase()
        ).query.returnurl
        const queryRedirect = parsedRedirectUrl
            ? queryString.parse(parsedRedirectUrl.toString())
            : ''
        const queryParam = queryRedirect ? queryRedirect.style?.toString() : ''

        setStyle(queryParam ?? style)
        if (style) {
            import(`../styling/${style}.css`).catch((e) => {
                window.console.warn(e)
            })
        }
    }, [style])

    const trigger = React.useCallback(async () => {
        const appId = signingRequest?.applicationId

        if (error !== undefined) {
            const signMethod = localStorage.getItem('signMethod')
            const tagManagerArgs = {
                dataLayer: {
                    applicationID: appId,
                    signingMethod: signMethod,
                    authenticationMethod: undefined,
                    event: GtmEvents.SigningFailed,
                    errorMessage: error.message,
                } as IDataLayer,
            } as ITagManagerArgs

            TagManager.dataLayer(tagManagerArgs)

            if (
                signingRequest?.documentType === documentTypes.consent &&
                signMethod === AuthMethodType[AuthMethodType.Paysera]
            ) {
                const tagManagerArgs = {
                    dataLayer: {
                        applicationID: appId,
                        signingMethod: signMethod,
                        authenticationMethod: undefined,
                        event: GtmEvents.ConsentFailed,
                        errorMessage: error.message,
                    } as IDataLayer,
                } as ITagManagerArgs

                TagManager.dataLayer(tagManagerArgs)
            }
        }
    }, [error, signingRequest])

    React.useEffect(() => {
        getStyle()
        trigger()
    }, [getStyle, trigger])

    const setSignedFunction = useCallback(
        (autoclose: boolean, redirectUrl: string, justSigned = true) => {
            if (justSigned && autoclose) {
                window.location.replace(redirectUrl)
                return
            }

            setSigningStage(SigningStage.complete)
        },
        []
    )

    const setFullError = useCallback((e: Error) => {
        setError(getErrorWithFullMessage(e))
    }, [])

    const setSigned = useCallback(
        (justSigned = true) =>
            setSignedFunction(autoclose, redirectUrl, justSigned),
        [autoclose, redirectUrl, setSignedFunction]
    )

    const fetchSigningRequest = useCallback(
        async (id: string, status: string) => {
            const user = await checkAuth(id, style)

            if (!user) return

            setUser(user)

            try {
                const signingRequest = await httpClient.getSigningRequestById(
                    id
                )
                setSigningRequest(signingRequest)
                setSigningStatus(status)

                if (status === payseraStatuses.complete) {
                    setSigningStage(SigningStage.signing)
                }
                else if (signingRequest?.status === SigningStatus.signed) {
                    setSignedFunction(
                        signingRequest.autoclose,
                        signingRequest.redirectUrl,
                        status === "authenticated"
                    )
                }
            } catch (e) {
                appInsights.trackException({ exception: e as Error })
                if (axios.isAxiosError(e)) {
                    const axiosError = e as AxiosError
                    const { status } = axiosError.response || { status: 0 }
                    const gte400ls500 = status >= 400 && status < 500

                    if (gte400ls500) {
                        return void userManager.signoutRedirect({
                            extraQueryParams: {
                                SigningRequestId: id,
                            },
                        })
                    }
                }

                setFullError(e as Error)
            }
        },
        [style, setSignedFunction, setFullError]
    )

    return (
        <SigningContext.Provider
            value={{
                signingRequest,
                signingStage,
                error,
                user,
                signingStatus,
                fetchSigningRequest,
                setError: setFullError,
                setSigned,
                setSigningStage,
                setSigningStatus,
            }}
        >
            {children}
        </SigningContext.Provider>
    )
}

export default SigningContext
