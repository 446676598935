import React, { useContext } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import payseraStatuses from '../../constants/payseraStatuses'
import { IPayseraCompleted } from '../../interfaces/i-paysera'
import SigningStatus from '../../constants/signingStatuses'
import { appConstants } from '../../constants/appConstants'
import { useTranslation } from 'react-i18next'
import httpClient from '../../services/httpClient'
import SigningContext from '../../contexts/SigningContext'
import { appInsights } from '../../appInsights'

const buttons = [
    [
        {
            dataBank: 'sampo',
            imgSrc: '/img/DanskeBank.png',
            altText: 'Danske Bank',
        },
        {
            dataBank: 'nordealt',
            imgSrc: '/img/LuminorNordea.png',
            altText: 'Luminor',
        },
        {
            dataBank: 'sb',
            imgSrc: '/img/SiauliuBankas.png',
            altText: 'JSC bank &quot;Šiaulių bankas&quot;',
        },
    ],
    [
        {
            dataBank: 'nord',
            imgSrc: '/img/LuminorDNB.png',
            altText: 'Luminor | DNB',
        },
        {
            dataBank: 'vb2',
            imgSrc: '/img/SEB.png',
            altText: 'SEB',
        },
        {
            dataBank: 'mb',
            imgSrc: '/img/MedicinosBankas.png',
            altText: 'JSC bank &quot;Medicinos Bankas&quot;',
        },
    ],
    [
        {
            dataBank: 'parex',
            imgSrc: '/img/Citadele.png',
            altText: 'Citadele',
        },
        {
            dataBank: 'hanza',
            imgSrc: '/img/Swedbank.png',
            altText: 'Swedbank',
        },
        {
            dataBank: 'lku',
            imgSrc: '/img/LKU.png',
            altText: 'LKU',
        },
    ],
]

function PayseraSignBlock() {
    const [payseraData, setPayseraData] = React.useState<IPayseraCompleted>()
    const { t } = useTranslation()
    const { signingRequest, signingStatus, setSigned, setError } =
        useContext(SigningContext)
    const id = signingRequest?.id ?? ''

    const checkPayseraStatus = React.useCallback(async () => {
        if (signingStatus === payseraStatuses.complete) {
            const data = await httpClient.payseraComplete(id)

            setPayseraData(data)

            if (data && data.isSigned) {
                setSigned()
            } else {
                checkSigningStatus(id, setSigned, setError)
            }
        }
    }, [id, setError, setSigned, signingStatus])

    React.useEffect(() => {
        checkPayseraStatus()
    }, [checkPayseraStatus])

    const initiatePaysera =
        (bank: string) => async (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            const data = await httpClient.payseraInitiate(id, bank)
            window.location.replace(data.url)
        }

    function goToSuccessUrl() {
        payseraData && window.location.replace(payseraData.successUrl)
    }

    if (payseraData) {
        return (
            <Row>
                <Col xs={12}>
                    <div className="text-center">
                        {t(
                            'Process is complete. Waiting for confirmation from Paysera, which can take anywhere between 10 seconds to 30 minutes. You may close this window and we will contact you with information regarding your final loan decision'
                        )}
                    </div>
                </Col>
                <Col xs={12} className="mt-3">
                    <Button variant="primary" onClick={goToSuccessUrl}>
                        {t('Close')}
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Container className="PayseraSign">
            <Row>
                <h3 className="text-center">
                    {t(
                        "Please select the bank you'd like to conduct the 1 cent transfer with"
                    )}
                    :
                </h3>
            </Row>
            <Row>
                <Col>
                    {buttons.map((row, i) => (
                        <Row key={i} className="mt-sm-3 text-center">
                            {row.map((bank) => (
                                <div
                                    className="col-sm-4 my-3 mb-sm-5 text-center"
                                    key={bank.dataBank}
                                >
                                    <a
                                        href="/"
                                        onClick={initiatePaysera(bank.dataBank)}
                                    >
                                        <img
                                            src={bank.imgSrc}
                                            alt={bank.altText}
                                            data-bank={bank.dataBank}
                                        />
                                    </a>
                                </div>
                            ))}
                        </Row>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

async function checkSigningStatus(
    id: string,
    signedCallback: () => void,
    onError: (error: Error | undefined) => void
) {
    try {
        onError(undefined)

        const signing = await httpClient.getSigningRequestById(id)
        if (
            signing.consentError !== undefined &&
            signing.consentError !== '' &&
            signing.consentError !== null
        ) {
            onError(new Error(signing.consentError))
        }

        const response = await httpClient.checkSigningRequestStatus(id)

        if (response.status === SigningStatus.signed) {
            signedCallback()
        } else {
            setTimeout(
                checkSigningStatus,
                appConstants.signingRequestsCheckTimeout,
                id,
                signedCallback,
                onError
            )
        }
    } catch (e) {
        appInsights.trackException({ exception: e as Error })
        onError(e as Error)
    }
}

export default PayseraSignBlock
