import React from "react";
import { Container, ListGroup, Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { appRoutes } from "../../constants/routes";
import { useTranslation } from "react-i18next";

function LogoutPage({ history }: RouteComponentProps<any>) {

    const { t } = useTranslation();

    return (
        <Container className="logoutPage">
            <ListGroup>
                <ListGroup.Item>
                    <h2 className="mt-1">{t("You are logged out")}</h2>
                </ListGroup.Item>
                <ListGroup.Item className="py-5">
                    <Button onClick={() => history.push(appRoutes.login)}><span className="glyphicon glyphicon-log-out" /> {t("Go to login page")}</Button>
                </ListGroup.Item>
            </ListGroup>
        </Container>
    )
}

export default LogoutPage;