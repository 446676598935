import React from "react";
import { Container, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import httpClient from "../../services/httpClient";
import { ITagManagerArgs } from "../../interfaces/i-tag-manager-args";
import { IDataLayer } from "../../interfaces/i-data-layer";
import { GtmEvents } from "../../constants/gtmEvents";
import TagManager from "../../components/TagManager";

function ErrorPage() {

    const { t } = useTranslation();
    const errorObject = queryString.parse(window.location.search);

    const trigger = React.useCallback(async () => {
        const tagManagerArgs = {
            dataLayer: {
                authenticationMethod: localStorage.getItem('authMethod'),
                event: GtmEvents.AuthenticationFailed,
                errorMessage: errorObject.errorMessage
            } as IDataLayer
        } as ITagManagerArgs;

        TagManager.dataLayer(tagManagerArgs);
    }, [errorObject.errorMessage]);

    React.useEffect(
        () => {
            trigger();
        },
        [trigger]
    );

    return (
        <Container className="errorPage">
            <Alert variant="danger">
                {t("Authentication error")}
                <br />
                {t(errorObject.errorMessage as string)}
            </Alert>
        </Container>
    )
}

export default ErrorPage;